
import React, { useContext, useEffect } from "react";

import { Modal, Container, Row, Col } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import LoginPana from "../../components/Image/LoginPana.svg";
import RegisterPana from "../../components/Image/RegisterPana.svg";
import { UserContext } from "../../Context";
import x from "../../components/Image/x.svg";
import '../../Pages/Styles/Home.css';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LOGIN, REGISTRACTION, SEND_OTP, CHANGE_PASSWORD, VERIFY_OTP } from "../../redux/constants/index";

import Login from "./Login";
import Registraction from "./Registraction";
import SendOTP from "./SendOTP";
import VerifyOTP from "./VerifyOTP";
import ChangePassword from "./ChangePassword"
import { switchAuthPage } from "../../redux/action/auth";
import "./style.scss"
import {toggleAuthModal} from "../../redux/action/auth";
export default function AuthModal() {
    const { showPage, showModal } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const history = useHistory();
    const { show, setshow } = useContext(UserContext);
    const { token, setToken } = useContext(UserContext);
    const [otp, setOtp] = React.useState("");
    const [stat, setstate] = React.useState("Login");
    const [activelg, setActiveL] = React.useState("p-auth-active");
    const [activereg, setActiveR] = React.useState("p-auth");
    const [active, setActive] = React.useState("authL");
    const [pana, setpana] = React.useState(LoginPana);


    const [cpassword, setCpassword] = React.useState("");
    const [name, setName] = React.useState("");
    const [response, setresponse] = React.useState("");

    const [isresetPassword, setResetPassword] = React.useState(false);
    const [resendOTP, setResendOTP] = React.useState(false);

    const [showSec, setShowSec] = React.useState(120);
    useEffect(() => setShowSec(showSec), [showSec]);
    const user_token = localStorage.getItem("Token");

    const handleChangePage = (pageName) => {
        if (pageName == LOGIN) {
            handleLg()
        } else if (pageName == Registraction) {
            handleRg()
        }
        dispatch(switchAuthPage(pageName))
    }


    function handleLg() {
        setActiveL("p-auth-active");
        setActiveR("p-auth");
        setActive("authL");
        setstate("Login");
        setpana(LoginPana);
        setresponse("");
    }
    function handleRg() {
        setActiveL("p-auth");
        setActiveR("p-auth-activeS");
        setActive("authR");
        setstate("Register");
        setpana(RegisterPana)
    }

    return (
        <div>
            <Modal className="main-modal-div" show={showModal}>
                <Modal.Body className="modal-auth">
                    <Container>
                        <Row>
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{
                                    backgroundColor: "#fff",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "start",
                                    borderRadius: "0px 17px 17px 0px",
                                }}
                            >
                                <Row>
                                    <Col
                                        md={12}
                                        style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            borderRadius: "0px 17px 17px 0px",
                                        }}
                                    >
                                        <Typography className={showPage == LOGIN ? "active-auth-item modal-auth-text" : "hover-item modal-auth-text"} onClick={() => handleChangePage(LOGIN)} variant="h5" gutterBottom component="div">
                                            Login
                                        </Typography>
                                        <Typography className={showPage == REGISTRACTION ? "active-auth-item modal-auth-text" : "hover-item modal-auth-text"} onClick={() => handleChangePage(REGISTRACTION)} variant="h5" gutterBottom component="div">
                                            Register
                                        </Typography>
                                    </Col>
                                    <Col xs={10} sm={10} md={10} lg={10} className={active}>
                                        <Row>
                                            {
                                                showPage == LOGIN ? <Login /> : null
                                            }
                                            {
                                                showPage == REGISTRACTION ? <Registraction /> : null
                                            }
                                            {
                                                showPage == SEND_OTP ? <SendOTP /> : null
                                            }
                                            {
                                                showPage == CHANGE_PASSWORD ? <ChangePassword /> : null
                                            }
                                            {
                                                showPage == VERIFY_OTP ? <VerifyOTP /> : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                md={6}
                                lg={6}
                                xl={6}
                                className="none"
                                style={{
                                    backgroundColor: "#F8F8F8",
                                    height: "100%",
                                    borderRadius: "0px 17px 17px 0px",
                                }}
                            >
                                <Col md={12} style={{ display: "flex", justifyContent: "end" }}>
                                    {" "}
                                    <a href="">
                                        <span
                                            onClick={() => {
                                                dispatch(toggleAuthModal(false))
                                            }}
                                        >
                                            <img
                                                src={x}
                                                alt=""
                                                height="20px"
                                                style={{ marginTop: "12px", marginRight: "12px" }}
                                            />
                                        </span>
                                    </a>
                                </Col>
                                <Col className="centerXY" md={12}>
                                    <img src={pana} style={{width:"90%", height:'auto', marginTop:'70px'}} alt="" />
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
