export const getCookie = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export const deleteCookie = (name, path, domain) => {
    if(getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "")+
            ((domain)? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
    }
}