
import React, { useEffect, useState } from "react"
import { Col, Row, Container } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import Avatar from '@mui/material/Avatar';
import { FcAlarmClock } from "react-icons/fc";
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { BiHash, BiTargetLock} from "react-icons/bi"
import { FaMedal } from 'react-icons/fa';
import { RiMedal2Fill } from "react-icons/ri";
import { TiTickOutline } from 'react-icons/ti';
import { ImCross } from "react-icons/im";
import { Helmet } from "react-helmet";
import axios from "../../axios";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import "../Quiz/style.css";
//import MathJax from 'react-mathjax-preview'
import { useDispatch } from "react-redux";
import { Danger } from "../../redux/action/toaster";
// import QuizTopperList from "../Quiz/QuizTopperList";
//import {RenderMathElement} from "./mathjaxPreview/MemoizedMathJaxComponent";
import CardSkeleton from "../../components/Skeleton/CardSkeleton";


export default function QuizresultApp(props) {

    //window.location.reload(); 
    const dispatch = useDispatch()
    const [userRank, setUserRank] = useState();
    const [highestmark, sethHightestMark] = useState()

    var timeCount = localStorage.getItem("Quiz_Completion_Time");
    const { hours, minutes, seconds } = JSON.parse(timeCount);

    const params = new URLSearchParams(window.location.search)

    var course_ID = params.get('course_ID')
    var exam_ID = params.get('exam_ID')
    var subscription_ID = params.get('subscription_ID')
    var isCatalogCourse = params.get('isCatalogCourse')

    const [result, setResult] = useState(localStorage.getItem(`${exam_ID}`))

    var token = params.get('token')

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [examRankData, setExamRankData] = useState({});

    var { questions, selectAnswer, title,
        noOfQuestion,
        marksPerQuestion,
        negativeMarksPerQuestion,
        durationInSec } = props.location.state.result


    var total_correct_answer = 0;
    var total_wrong_answer = 0;
    var correct_index = [];
    var wrong_index = [];
    var total_questions = questions.length;

    if (questions.length > 0) {
        questions.map(item => {
            selectAnswer.map(item2 => {
                if (item.serialNo == item2.question + 1) {
                    if (item.correctAns == item2.selected) {

                        total_correct_answer += 1;
                        correct_index.push(item.serialNo)

                    } else {
                        total_wrong_answer += 1;
                        wrong_index.push({ serial: item.serialNo, selected: item2.selected })
                    }
                }
            })
        })
    }

    var obtainedMarks = marksPerQuestion * total_correct_answer - total_wrong_answer * negativeMarksPerQuestion

    // cdn(mathJax relode)
    useEffect(() => {
        // window.location.reload(); 
        // window.location.reload(false);
    }, [])

    useEffect(() => {
        getExamRank();
    }, []);

    useEffect(() => {
        return () => {
            window.location.reload();
        }
    }, [])

    const getExamRank = async () => {
        const url = `/mcq-exam-results/view-rank?subscriptionId=${subscription_ID}&courseId=${course_ID}&examId=${exam_ID}&isCatalogCourse=${isCatalogCourse}`
        const response = await axios.get(url, {
            headers: {
                "x-auth-token": token,
                'Content-Type': 'application/json',
            }
        })
        setExamRankData(response.data)
        setLoading(false);
    }

    if (questions.length == undefined || questions.length == 0) {
        return <Container>
            <Row className="d-flex justify-content-center" style={{ paddingTop: '100px', marginBottom: '20px', width: '100%' }}>
                <Row style={{ width: '100%',padding: '20px' }} className="quiz-result-main d-flex justify-content-center align-items-center">
                    Quiz Not Found
                </Row>
            </Row>
        </Container>
    }


    const Correct_Text = <> <Col style={{ color: 'green', textAlign :'left' }}>Correct </Col> </>
    const wrong_Text = <> <Col style={{ color: 'red', textAlign :'left' }}>Wrong </Col> </>
    const unanswered_Text = <> <Col style={{ color: '#6c757d', textAlign :'left' }}>Not Answered </Col> </>

    window.onunload = () => {
        localStorage.removeItem(exam_ID);
    }

    return (
        <div className="table-border-wrap">
            {
                loading ? <CardSkeleton />
                    :
                    <div>
                        <Row className="d-flex justify-content-center" style={{ paddingTop: '10px', marginBottom: '20px', width: '100%', paddingLeft: '20px' }}>
                            <Row style={{ width: '100%', padding: '5px' }} className="d-flex justify-content-center align-items-center">

                                <Paper style={{ width: '100%' }} variant="outlined" square>
                                    <Row style={{ width: '100%' }} className="d-flex justify-content-center align-items-center">
                                        <Row style={{ fontSize: '15px', marginTop: '10px', textAlign: 'center', color : 'rgb(41, 83, 155)' }} className="ltb centerXY">
                                            {title}
                                        </Row>

                                        <Row style={{ fontSize: '10px', marginTop: '10px' }}>
                                         <div className="str centerXY"><BsFillQuestionCircleFill />&nbsp;Total Question: {questions.length}</div>
                                        </Row>

                                        <Row style={{ fontSize: '10px', marginTop: '10px' }}>
                                        <div className="str centerXY"><BiHash />{" "}Total Marks: {marksPerQuestion * questions.length}</div>
                                        </Row>

                                        <Row style={{ fontSize: '10px', marginTop: '10px', textAlign: 'center' }}>
                                            <div style={{direction : 'column' , color : 'green'}} className="str centerXY"> 
                                            <TiTickOutline />&nbsp;{marksPerQuestion} Mark/Question
                                            </div>
                                        </Row>

                                        <Row style={{ fontSize: '10px', marginTop: '10px', textAlign: 'center' }}>
                                            <div style={{direction : 'column', color : 'red'}} className="str centerXY"> 
                                            <ImCross style={{fontSize : '10px'}} />&nbsp;-{negativeMarksPerQuestion}/Wrong Question
                                            </div>
                                        </Row>

                                        <Row style={{marginTop: '10px' }} className="str centerXY">
                                            ⏰&nbsp;{(durationInSec / 60).toFixed(2)} Minutes
                                        </Row>

                                        <Row style={{ marginTop: '10px' }}>
                                            <div style={{height : '40px'}} className="position-display">
                                                <div className="str" style={{ textAlign: 'center'}}>Obtained Marks : {obtainedMarks}</div>
                                            </div>
                                        </Row>

                                        <Row style={{marginTop: '10px' }}>
                                          <div className="str centerXY">
                                              <RiMedal2Fill/>&nbsp;Exam Rank: {examRankData.userRank}
                                           </div>
                                        </Row>

                                        <Row style={{marginTop: '10px' }}>
                                            <div className="str centerXY">
                                            <FaMedal style={{color: 'green'}}/>&nbsp;Highest Marks: {examRankData.toppers[0] ? examRankData.toppers[0].obtainedMarks : null}
                                            </div>
                                        </Row>

                                        {/* <Row  style={{fontSize:'10px', marginTop: '10px' }} className="str centerXY">
                                           <QuizTopperList className="centerXY" toppers={examRankData.toppers} myRankData={examRankData.userDetails} myRank={examRankData.userRank} />
                                        </Row>
                                        */}
                                    </Row>

                                    <Row style={{ marginTop: '20px', marginBottom: '20px' }} className="d-flex flex-row centerXY">

                                        <Col style={{ width: '25%' }}>
                                            <div className="small-result-box">
                                                <strong className="correct-ans-small-text stb">{total_correct_answer}</strong>
                                                <CheckCircleIcon className="small-result-box-icon" />
                                            </div>
                                            <p style={{ padding: '0px', textAlign: 'center', fontSize: '10px' }} className="badge-text-footer-apps str">Correct</p>

                                        </Col>

                                        <Col style={{ width: '25%' }}>
                                            <div className="small-result-box">
                                                <strong className="correct-ans-small-text stb">{total_wrong_answer}</strong>
                                                <CancelIcon style={{ color: 'red' }} className="small-result-box-icon" />
                                            </div>
                                            <p style={{ padding: '0px', textAlign: 'center', fontSize: '10px' }} className="badge-text-footer-apps str">Wrong</p>
                                        </Col>

                                        <Col style={{ width: '25%' }}>
                                            <div className="small-result-box">
                                                <strong className="correct-ans-small-text stb">{Math.abs(total_correct_answer + total_wrong_answer - questions.length)}</strong>
                                                <NoteAltRoundedIcon style={{ color: 'blue' }} className="small-result-box-icon" />
                                            </div>
                                            <p style={{ padding: '0px', textAlign: 'center', fontSize: '10px' }} className="badge-text-footer-apps str">Unanswered</p>
                                        </Col>

                                    </Row>

                                </Paper>
                            </Row>


                            {/*  SHOW ALL ANSWER */}

                            <Row style={{ width: '100%', padding: '5px' }} className="quiz-result-main-small d-flex justify-content-center align-items-center">
                               
                                    <Helmet>
                                        <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
                                        <script
                                            id="MathJax-script"
                                            async
                                            src="https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js"
                                        ></script>
                                    </Helmet>
                                    <Row style={{ width: '100%', padding : '0px' }} className="d-flex justify-content-center align-items-center">

                                        {
                                            questions.map((item, index) =>
                                                <Paper key={index} style={{ width: '100%', margin : '10px 0px' }} variant="outlined" square>
                                                <Col key={index} style={{ flexDirection: 'column', width : '100%', padding : '0px' }} className="centerXY" sm={12} md={6}>
                                                    <Row className="total-question mtr" 
                                                        style={{fontSize: '14px', marginTop: '5px', width: '100%', textAlign : 'right', marginLeft : '0px' }}>
                                                       
                                                            <Col xs={10} md={10}>
                                                                <Row className="question-shown centerXY" style={{ display : 'flex', flexDirection : 'column', alignItems : 'center',
                                                                   overflowX: 'auto', fontSize: '14px', marginTop: '5px', marginBottom: '5px', width: '100%', textAlign : 'left' }}>
                                                                   <Col md={12}>
                                                                   <div
                                                                        dangerouslySetInnerHTML={{ __html: `${index+1}. &nbsp; ${item.question}` }}
                                                                    />
                                                                   </Col>
                                                                  
                                                                </Row>
                                                            </Col>

                                                       <Col md={12}>
                                                       {Boolean(wrong_index.find(x => x.serial == index + 1)) ? wrong_Text : correct_index.includes(index + 1) ? Correct_Text : unanswered_Text}
                                                       </Col>                                                       
                                                    </Row>

                                                    
                                                    <Row className={item.correctAns == 'A' ?
                                                        "hover-item que-top-shadow correct-answer-mcq centerXY" :
                                                        Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'A')) ?
                                                            "hover-item bg-red centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
                                                        <Avatar

                                                            className={item.correctAns == 'A' ? "ques-result-selected app-que-avatar-size" : Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'A')) ? "app-que-avatar-size ques-result-wrong" : "app-que-avatar-size ques-result-unselected"}>
                                                            A
                                                        </Avatar>
                                                        &nbsp; &nbsp;
                                                        {/* <RenderMathElement data={item.optionA} /> */}
                                                        <div style={{ overflowX: 'auto' }}> <div
                                                            dangerouslySetInnerHTML={{ __html: item.optionA }}
                                                        /></div>
                                                    </Row>


                                                    <Row className={item.correctAns == 'B' ?
                                                        "hover-item que-top-shadow correct-answer-mcq centerXY" :
                                                        Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'B')) ?
                                                            "hover-item bg-red centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
                                                        <Avatar className={item.correctAns == 'B' ? "app-que-avatar-size ques-result-selected" : Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'B')) ? "app-que-avatar-size ques-result-wrong" : "app-que-avatar-size ques-result-unselected"}>B</Avatar> &nbsp; &nbsp;
                                                        {/* <RenderMathElement data={item.optionB} /> */}
                                                        <div style={{ overflowX: 'auto' }}> <div
                                                            dangerouslySetInnerHTML={{ __html: item.optionB }}
                                                        /></div>
                                                    </Row>

                                                    <Row className={item.correctAns == 'C' ?
                                                        "hover-item que-top-shadow correct-answer-mcq centerXY" :
                                                        Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'C')) ?
                                                            "hover-item bg-red centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
                                                        <Avatar className={item.correctAns == 'C' ? "app-que-avatar-size ques-result-selected" : Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'C')) ? "app-que-avatar-size ques-result-wrong" : "app-que-avatar-size ques-result-unselected"}>C</Avatar> &nbsp; &nbsp;

                                                        {/* <RenderMathElement data={item.optionC} /> */}
                                                        <div style={{ overflowX: 'auto' }}> <div
                                                            dangerouslySetInnerHTML={{ __html: item.optionC }}
                                                        /></div>
                                                    </Row>

                                                    <Row className={item.correctAns == 'D' ?
                                                        "hover-item que-top-shadow correct-answer-mcq centerXY" :
                                                        Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'D')) ?
                                                            "hover-item bg-red centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
                                                        <Avatar className={item.correctAns == 'D' ? "app-que-avatar-size ques-result-selected" : Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'D')) ? "app-que-avatar-size ques-result-wrong" : "app-que-avatar-size ques-result-unselected"}>D</Avatar> &nbsp; &nbsp;
                                                        {/* <RenderMathElement data={item.optionD} /> */}
                                                        <div style={{ overflowX: 'auto' }}> <div
                                                            dangerouslySetInnerHTML={{ __html: item.optionD }}
                                                        /></div>
                                                    </Row>

                                                    {
                                                        item.correctAns == 'E' ?
                                                            <Row className={item.correctAns == 'E' ?
                                                                "hover-item que-top-shadow correct-answer-mcq centerXY" :
                                                                Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'E')) ?
                                                                    "hover-item bg-red centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
                                                                <Avatar className={item.correctAns == 'E' ? "app-que-avatar-size ques-result-selected" : Boolean(wrong_index.find(x => x.serial == index + 1 && x.selected === 'E')) ? "app-que-avatar-size ques-result-wrong" : "app-que-avatar-size ques-result-unselected"}>E</Avatar> &nbsp; &nbsp;
                                                                {/* <RenderMathElement data={item.optionE} /> */}
                                                                <div style={{ overflowX: 'auto' }}> <div
                                                                    dangerouslySetInnerHTML={{ __html: item.optionE }}
                                                                /></div>
                                                            </Row> : null
                                                    }

                                                    {
                                                        item.solution ?
                                                            <Row className="hover-item que-top-shadow" style={{ padding: '10px', marginBottom: '15px', width: '100%', backgroundColor: '#82828221' }}>
                                                                <Row>
                                                                    <Col className="centerXY" style={{ width: '100%', padding: '10px' }}>
                                                                        <CheckCircleIcon style={{ color: 'green' }} />&nbsp; &nbsp;Solution
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col className="centerXY" style={{ width: '100%', padding: '10px' }}>
                                                                        {/* <RenderMathElement data={item.solution} /> */}
                                                                        <div style={{ overflowX: 'auto' }}> <div
                                                                            dangerouslySetInnerHTML={{ __html: item.solution }}
                                                                        /></div>
                                                                    </Col>
                                                                </Row>
                                                            </Row> : null
                                                    }
                                                </Col>
                                                </Paper>
                                            )
                                        }
                                    </Row>
                                
                            </Row>
                        </Row>
                    </div>
            }
        </div>
    );
}
