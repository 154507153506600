import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch} from 'react-redux';
import { Danger } from '../../redux/action/toaster';

import axios from '../../axios';


import InstitutionSearch from '../../Pages/MyAccount/InstitutionSearch';
import { validateInstitution } from '../../redux/action/auth';
import { Success } from '../../redux/action/toaster';

export default function FormDialog() {

    const dispatch = useDispatch()
    const [selectInstitution, setInstitution] = React.useState('')
    const { token, isVerifiedInstitution, institution } = useSelector(state => state.auth)
    const [isValidInstitue, setValidInstitute] = React.useState(false);

    if (!token) return null

    const handleCheckInstitutionValidation = () => {
    }
    
    const onSelectInstitute = (newInstitute)=>{
        if(newInstitute){
            setInstitution(newInstitute.title)
            setValidInstitute(true)
        }else{
            setValidInstitute(false)
        }
    }

    const handleUpdateInstitute = async() => {
        if(!isValidInstitue) return dispatch(Danger("Select a valid institution"))
        const body = {
            institution : selectInstitution
        }

        const response = await axios.put(`/me/institution`, body, {
            headers: {
                "x-auth-token": token,
                'Content-Type': 'application/json',
            }
        })
        dispatch(Success(`${response.data}`))
        if(response.status == 200){
            dispatch(validateInstitution(`'success'`))
        }
       
    }

    return (
        <div>
            <Dialog open={!isVerifiedInstitution}>
                <DialogTitle>Select a valid institution</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{mb : 2}}>
                        To continue to this website, please select your institution here. We
                        will send updates occasionally.
                    </DialogContentText>
                    <InstitutionSearch
                        handleCheckInstitutionValidation={(data) => handleCheckInstitutionValidation(data)}
                        defaultInstitute={{ title: institution }}
                        onSelectInstitute={(newInstitute) => onSelectInstitute(newInstitute)} />
                </DialogContent>
                {
                    selectInstitution && isValidInstitue  ?
                    <Button onClick={handleUpdateInstitute}>Update</Button>  : null
                }
            
    
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}
