import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Avatar from "@mui/material/Avatar";
import "../style.css"

export default function AnswerBox({ wrong_index, index, item, correct_index }) {
    const Correct_Text = (
        <>
            {" "}
            <Col style={{ color: "green" }}>Correct </Col>{" "}
        </>
    );
    const wrong_Text = (
        <>
            {" "}
            <Col style={{ color: "red" }}>Wrong </Col>{" "}
        </>
    );
    const unanswered_Text = (
        <>
            {" "}
            <Col style={{ color: "#6c757d" }}>Not Answered </Col>{" "}
        </>
    );

    return (
        <div style={{ marginBottom: '15px' }}>
            <Col
                style={{ flexDirection: "column" }}
                className="centerXY"
            >
                <Row
                    className="total-question mtr"
                    style={{ marginTop: "15px", width: "100%" }}
                >
                    <Col md={10}>
                        <Row
                            className="question-shown centerXY"
                            style={{
                                padding: "5px 0px",
                                overflowX: "auto",
                                marginTop: "15px",
                                marginBottom: "15px",
                                width: "100%",
                                textAlign : 'left',
                                direction : 'column'
                            }}
                        >   
                            <Col md={12}><div
                                dangerouslySetInnerHTML={{ __html: `${index + 1}. &nbsp;${item.question}`} }
                            /></Col>
                        </Row>
                    </Col>
                    <Col md={2}>
                        <div style={{ maxWidth: '40%', marginTop: '10px', textAlign: 'right' }}>
                            {Boolean(wrong_index.find((x) => x.serial == index + 1))
                                ? wrong_Text
                                : correct_index.includes(index + 1)
                                    ? Correct_Text
                                    : unanswered_Text}
                        </div>
                    </Col>
                </Row>


                <Row style={{ width: '100%', paddingLeft : '15px' }} md={12}>

                    {/* Option A */}
                    <Col md={6} xs={12}>
                        <Row
                            className={
                                item.correctAns == "A"
                                    ? "hover-item que-top-shadow correct-answer-mcq centerXY"
                                    : Boolean(
                                        wrong_index.find(
                                            (x) =>
                                                x.serial == index + 1 && x.selected === "A"
                                        )
                                    )
                                        ? "hover-item bg-red centerXY"
                                        : "hover-item que-top-shadow centerXY"
                            }
                            style={{
                                padding: "10px",
                                width: "100%",
                                flexFlow: "row",
                            }}
                        >
                            <Avatar
                                className={
                                    item.correctAns == "A"
                                        ? "ques-result-selected"
                                        : Boolean(
                                            wrong_index.find(
                                                (x) =>
                                                    x.serial == index + 1 && x.selected === "A"
                                            )
                                        )
                                            ? "ques-result-wrong"
                                            : "ques-result-unselected"
                                }
                            >
                                A
                            </Avatar>
                            &nbsp; &nbsp;
                            <div style={{ overflowX: "auto", padding: "5px" }}>
                                {" "}
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.optionA }}
                                />
                            </div>
                        </Row>
                    </Col>

                    {/* Option B */}
                    <Col md={6} xs={12}>
                        <Row
                            className={
                                item.correctAns == "B"
                                    ? "hover-item que-top-shadow correct-answer-mcq centerXY"
                                    : Boolean(
                                        wrong_index.find(
                                            (x) =>
                                                x.serial == index + 1 && x.selected === "B"
                                        )
                                    )
                                        ? "hover-item bg-red centerXY"
                                        : "hover-item que-top-shadow centerXY"
                            }
                            style={{
                                padding: "10px",
                                width: "100%",
                                flexFlow: "row",
                            }}
                        >
                            <Avatar
                                className={
                                    item.correctAns == "B"
                                        ? "ques-result-selected"
                                        : Boolean(
                                            wrong_index.find(
                                                (x) =>
                                                    x.serial == index + 1 && x.selected === "B"
                                            )
                                        )
                                            ? "ques-result-wrong"
                                            : "ques-result-unselected"
                                }
                            >
                                B
                            </Avatar>{" "}
                            &nbsp; &nbsp;
                            <div style={{ overflowX: "auto", padding: "5px" }}>
                                {" "}
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.optionB }}
                                />
                            </div>
                        </Row>
                    </Col>

                    {/* Option C */}
                    <Col md={6} xs={12}>
                        <Row
                            className={
                                item.correctAns == "C"
                                    ? "hover-item que-top-shadow correct-answer-mcq centerXY"
                                    : Boolean(
                                        wrong_index.find(
                                            (x) =>
                                                x.serial == index + 1 && x.selected === "C"
                                        )
                                    )
                                        ? "hover-item bg-red centerXY"
                                        : "hover-item que-top-shadow centerXY"
                            }
                            style={{
                                padding: "10px",
                                width: "100%",
                                flexFlow: "row",
                            }}
                        >
                            <Avatar
                                className={
                                    item.correctAns == "C"
                                        ? "ques-result-selected"
                                        : Boolean(
                                            wrong_index.find(
                                                (x) =>
                                                    x.serial == index + 1 && x.selected === "C"
                                            )
                                        )
                                            ? "ques-result-wrong"
                                            : "ques-result-unselected"
                                }
                            >
                                C
                            </Avatar>{" "}
                            &nbsp; &nbsp;
                            <div style={{ overflowX: "auto", padding: "5px" }}>
                                {" "}
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.optionC }}
                                />
                            </div>
                        </Row>
                    </Col>

                    {/* Option D */}
                    <Col md={6} xs={12}>
                        <Row
                            className={
                                item.correctAns == "D"
                                    ? "hover-item que-top-shadow correct-answer-mcq centerXY"
                                    : Boolean(
                                        wrong_index.find(
                                            (x) =>
                                                x.serial == index + 1 && x.selected === "D"
                                        )
                                    )
                                        ? "hover-item bg-red centerXY"
                                        : "hover-item que-top-shadow centerXY"
                            }
                            style={{
                                padding: "10px",
                                width: "100%",
                                flexFlow: "row",
                            }}
                        >
                            <Avatar
                                className={
                                    item.correctAns == "D"
                                        ? "ques-result-selected"
                                        : Boolean(
                                            wrong_index.find(
                                                (x) =>
                                                    x.serial == index + 1 && x.selected === "D"
                                            )
                                        )
                                            ? "ques-result-wrong"
                                            : "ques-result-unselected"
                                }
                            >
                                D
                            </Avatar>{" "}
                            &nbsp; &nbsp;
                            <div style={{ overflowX: "auto", padding: "5px" }}>
                                {" "}
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.optionD }}
                                />
                            </div>
                        </Row>
                    </Col>

                    {/* Option E */}
                    <Col md={6} xs={12}>
                        {item.correctAns == "E" ? (
                            <Row
                                className={
                                    item.correctAns == "E"
                                        ? "hover-item que-top-shadow correct-answer-mcq centerXY"
                                        : Boolean(
                                            wrong_index.find(
                                                (x) =>
                                                    x.serial == index + 1 && x.selected === "E"
                                            )
                                        )
                                            ? "hover-item bg-red centerXY"
                                            : "hover-item que-top-shadow centerXY"
                                }
                                style={{
                                    padding: "10px",
                                    width: "100%",
                                    flexFlow: "row",
                                }}
                            >
                                <Avatar
                                    className={
                                        item.correctAns == "E"
                                            ? "app-que-avatar-size ques-result-selected"
                                            : Boolean(
                                                wrong_index.find(
                                                    (x) =>
                                                        x.serial == index + 1 &&
                                                        x.selected === "E"
                                                )
                                            )
                                                ? "app-que-avatar-size ques-result-wrong"
                                                : "app-que-avatar-size ques-result-unselected"
                                    }
                                >
                                    E
                                </Avatar>{" "}
                                &nbsp; &nbsp;
                                <div style={{ overflowX: "auto", padding: "5px" }}>
                                    {" "}
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item.optionE }}
                                    />
                                </div>
                            </Row>
                        ) : null}
                    </Col>

                    <Col md={12}>
                        {item.solution ? (
                            <Row
                                className="hover-item que-top-shadow"
                                style={{
                                    padding: "10px",
                                    marginBottom: "15px",
                                    width: "100%",
                                    backgroundColor: "#82828221",
                                }}
                            >
                                <Row>
                                    <Col
                                        className="centerXY"
                                        style={{ width: "100%", padding: "10px" }}
                                    >
                                        <CheckCircleIcon style={{ color: "green" }} />
                                        &nbsp; &nbsp;Solution
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        className="centerXY"
                                        style={{ width: "100%", padding: "10px" }}
                                    >
                                        <div style={{ overflowX: "auto", padding: "5px" }}>
                                            {" "}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.solution,
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        ) : null}
                    </Col>
                </Row>
            </Col>
        </div>
    )
}
