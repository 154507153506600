import React, { useEffect, useState } from "react"
import { Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SimpleBackdrop from "../../../components/Loading/SimpleBackdrop"
import axios from "../../../axios";
import { RenderHeader } from "./Header";
import { RenderMathQuestion } from "./Question";
import "../style.css";
import { useHistory } from "react-router";
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import { Danger } from "../../../redux/action/toaster";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardSkeleton from "../../../components/Skeleton/CardSkeleton"

export default function AppExam() {
   const dispatch = useDispatch()
   const history = useHistory();
   const [loading, setLoading] = useState(true)
   const [data, setData] = useState();
   const [selectAnswer, setSelectAnswer] = useState([]);
   const [current_page_number, setCurrentPageNumber] = useState(1);
   const params = new URLSearchParams(window.location.search)
   var course_id = params.get('course_ID')
   var exam_id = params.get('exam_ID')
   var subscription_id = params.get('subscription_ID')
   var isCatalogCourse = params.get('isCatalogCourse')
   var token = params.get('token')
   var isCatalogCourse= params.get("isCatalogCourse")

   //hook and function for modal
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   //styling for modal
   const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 380,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
   };

   const pagerender = (page) => {
      setCurrentPageNumber(page)
   }
   const onchangeSelectAnswer = (selectAnswer) => {
      setSelectAnswer(selectAnswer)
   }
   const onchangeSelectAnswerMbl = (selectAnswer) => {
      setSelectAnswer(selectAnswer)
   }

   const createExamApi = async () => {
      const examCreateUrl = `/mcq-exam-results/create-mcq-exam-result?subscriptionId=${subscription_id}&courseId=${course_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
      let getQuestionsAnswer = `/my-courses/questions-answer?courseId=${course_id}&subscriptionId=${subscription_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
      const response = await axios.get(getQuestionsAnswer, {
         headers: {
           "x-auth-token": token,
           "Content-Type": "application/json",
         },
       });
      var questions = response?.data
      var {
        marksPerQuestion,
        negativeMarksPerQuestion,
        durationInSec,
      } = data;
  
      var total_correct_answer = 0;
      var total_wrong_answer = 0;
      var correct_index = [];
      var wrong_index = [];
      var total_questions = questions.length;
      if (questions.length > 0) {
        questions.map((item) => {
          selectAnswer?.map((item2) => {
            if (item.serialNo == item2.question + 1) {
              if (item.correctAns == item2.selected) {
                total_correct_answer += 1;
                correct_index.push(item.serialNo);
              } else {
                total_wrong_answer += 1;
                wrong_index.push({
                  serial: item.serialNo,
                  selected: item2.selected,
                });
              }
            }
          });
        });
      }
      var timeCount = localStorage.getItem("Quiz_Completion_Time");
      const { hours, minutes, seconds } = JSON.parse(timeCount);
  
      const avgTimePerQuestionInSec = Math.abs(
        (hours * 3600 + minutes * 60 + seconds - durationInSec) / questions.length);
      try {
        const data = {
          noOfQuestion: total_questions,
          correct: total_correct_answer,
          wrong: total_wrong_answer,
          notAnswered: Math.abs(
            total_correct_answer + total_wrong_answer - questions.length
          ),
          obtainedMarks:
            marksPerQuestion * total_correct_answer -
            total_wrong_answer * negativeMarksPerQuestion,
          avgTimePerQuestionInSec: avgTimePerQuestionInSec,
        };
        setLoading(true)
        const response = await axios.post(examCreateUrl, data, {
          headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
          },
        });
        setLoading(false)
      } catch (error) {
        dispatch(Danger(`${error?.response.data}`));
      }
      return questions
    };

   const onTimeComplete = async () => {
     const questionsAnswer = await createExamApi();
     let questions = [];
     for (var index in data.questions) {
       let body = { ...data.questions[index] };
       body.correctAns = questionsAnswer[index].correctAns;
       questions.push(body);
     }
     const {
       title,
       noOfQuestion,
       marksPerQuestion,
       negativeMarksPerQuestion,
       durationInSec,
     } = data;
     const result = {
       questions,
       selectAnswer,
       title,
       noOfQuestion,
       marksPerQuestion,
       negativeMarksPerQuestion,
       durationInSec,
     };
     history.push({
       pathname: "/app/test/result",
       search: `?course_ID=${course_id}&exam_ID=${exam_id}&subscription_ID=${subscription_id}&token=${token}&isCatalogCourse=${isCatalogCourse}`,
       state: { result },
     });
     window.location.reload();
   };

   const handleSubmitQuiz = async () => {
     const questionsAnswer = await createExamApi();
     let questions = [];
     for (var index in data.questions) {
       let body = { ...data.questions[index] };
       body.correctAns = questionsAnswer[index].correctAns;
       questions.push(body);
     }
     const {
       title,
       noOfQuestion,
       marksPerQuestion,
       negativeMarksPerQuestion,
       durationInSec,
     } = data;
     const result = {
       questions,
       selectAnswer,
       title,
       noOfQuestion,
       marksPerQuestion,
       negativeMarksPerQuestion,
       durationInSec,
     };
     history.push({
       pathname: "/app/test/result",
       search: `?course_ID=${course_id}&exam_ID=${exam_id}&subscription_ID=${subscription_id}&token=${token}&isCatalogCourse=${isCatalogCourse}`,
       state: { result },
     });
     window.location.reload();
   };

   useEffect(() => {
      const url = `/my-courses/questions?courseId=${course_id}&subscriptionId=${subscription_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
      const fetchData = async () => {
         try {
            const response = await axios.get(url, {
               headers: {
                  "x-auth-token": token,
                  'Content-Type': 'application/json',
               }
            });
            setData(response.data);
            setLoading(false);
         } catch (error) {
            //dispatch(Danger(`${error.response.data}`))
            setLoading(false);
         }
      };
      fetchData();
   }, []);

   if (!loading) {
      if (!data) {
         return <Container>
            <Row className="d-flex justify-content-center" style={{ paddingTop: '100px', marginBottom: '20px', width: '100%' }}>
               <h2>No Quiz Found </h2>
            </Row>
         </Container>
      }
   }
   return (
      <div className="centerXY table-border-wrap" style={{ width: '100%', paddingLeft: '5px' }}>
         {
            loading ? <CardSkeleton /> :
               <Row className="d-flex justify-content-center" style={{ paddingTop: '5px', marginBottom: '10px', width: '100%' }}>
                  <Helmet>
                     <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
                     <script
                        id="MathJax-script"
                        async
                        src="https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js"
                     ></script>
                  </Helmet>
                  <Row style={{ width: '100%', padding: '0px' }} className="centerXY">
                     <RenderHeader
                        title={data.title}
                        course={data.course}
                        examType={data.examType}
                        noOfQuestion={data.noOfQuestion}
                        marksPerQuestion={data.marksPerQuestion}
                        negativeMarksPerQuestion={data.negativeMarksPerQuestion}
                        durationInSec={data.durationInSec}
                        onTimeComplete={onTimeComplete}
                     />
                  </Row>

                  <Row style={{ width: '92%', padding: '0px', margin: '10px' }} className="question-main-div centerXY">
                     <RenderMathQuestion
                        page={current_page_number - 1}
                        question={data.questions}
                        noOfQuestion={data.questions.length}
                        onchangeSelectAnswer={(selectAnswer) => onchangeSelectAnswer(selectAnswer)}
                     />
                  </Row>

                  <Row className="question-main-div" style={{ width: '100%' }} >
                     <Row style={{ width: '100%', marginTop: '-35px', marginBottom: '20px', marginRight: '10px' }}>
                        {/* <Button sx={{textTransform : 'none'}} onClick={handleSubmitQuiz} variant="contained">Submit</Button> */}
                        <div
                           style={{ backgroundColor: "#0066CC" }}
                           className="btn-block btn"
                           onClick={handleOpen}
                        >
                           PROCEED
                        </div>
                     </Row>
                  </Row>

                  <Modal
                     open={open}
                     onClose={handleClose}
                     aria-labelledby="modal-modal-title"
                     aria-describedby="modal-modal-description"
                  >
                     <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                           Ready to submit?
                        </Typography>

                        <div className="d-grid gap-2">
                           <Button size="lg" style={{ backgroundColor: "#27AE60", color : 'white' }} onClick={handleSubmitQuiz}>
                              Submit
                           </Button>
                           <Button size="lg" style={{ backgroundColor: "#C0392B", color : 'white' }} onClick={handleClose}>
                              Go back
                           </Button>
                        </div>
                     </Box>
                  </Modal>

               </Row>
         }
      </div>
   );
}





