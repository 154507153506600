
import {BrowserRouter, Switch, Route } from "react-router-dom";

/* @CUSTOM COMPONENT */
import asyncComponent from "./components/AsyncComponent";
import FormDialog from "./components/Modal/InstitutionModal";
import AppExam from "./Pages/App/mathjaxPreview/AppExam";
import WrittenExam from "./Pages/App/Writtenexam/WrittenExam";
import QuizresultApp from "./Pages/App/QuizresultApp";
import Appliveclass from "./Pages/App/Appliveclass"
import PlayWebFreeVideo from "./Pages/App/PlayWebFreeVideo";
import NotFound from './components/NotFound/NotFound';
import Quiz from "./Pages/Quiz/Quiz"
import QuizResult from "./Pages/Quiz/QuizResult"
import PrivateRoute from "./components/PrivateRoute"
import Notice from "./Pages/App/Notice/Notice"
import RefundPolicy from "./Pages/RefundPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Legal from "./Pages/Legal";
import { deleteCookie, getCookie } from "./helper/cookie";

/* DYNAMIC RENDERING COMPONENT */
const LandingPage = asyncComponent(() =>
	import('./Pages/LandingPage').then(module => module.default)
)
const HomeIndex = asyncComponent(() =>
	import('./Pages/Homepage/HomeIndex').then(module => module.default)
)
const Appbar = asyncComponent(() =>
	import('./components/Header/Appbar').then(module => module.default)
)
const Subscription = asyncComponent(() =>
	import('./Pages/Subscription/Subscription').then(module => module.default)
)
const SubscriptionBuy = asyncComponent(() =>
	import('./Pages/SubscriptionBuy').then(module => module.default)
)
const FreeCouese = asyncComponent(() =>
	import('./Pages/Course/FreeCourse').then(module => module.default)
)
const RecordedClass = asyncComponent(() =>
	import('./Pages/RecordedClass/RecordedClass').then(module => module.default)
)
const SetGoal = asyncComponent(() =>
	import('./Pages/SetGoal').then(module => module.default)
)
// const MyAccount = asyncComponent(() =>
// 	import('./Pages/MyAccount').then(module => module.default)
// )
const MyAccount = asyncComponent(() =>
	import('./Pages/MyAccount/MyAccount').then(module => module.default)
)
// const Quiz = asyncComponent(() =>
// 	import('./Pages/Quiz/Quiz').then(module => module.default)
// )
// const CourseDetails = asyncComponent(() =>
// 	import('./Pages/Course/CourseDetails').then(module => module.default)
// )
const CourseDetails = asyncComponent(()=>
 import('./Pages/Course/CourseDetails/CourseDetails').then(module => module.default)
)
const CatalogCourseDetails = asyncComponent(() =>
	import('./Pages/Course/CatalogCourse/Details/CatalogCourseDetails').then(module => module.default)
) 
const Dashboard = asyncComponent(() =>
	import('./Pages/Dashboard').then(module => module.default)
)
const MyDashboard = asyncComponent(() =>
	import('./Pages/Dashboard/MyDashboard').then(module => module.default)
)
// const MySubject = asyncComponent(() =>
// 	import('./Pages/MySubject').then(module => module.default)
// )
const SubjectList = asyncComponent(() =>
	import('./Pages/Subject/SubjectList').then(module => module.default)
)
const Routine = asyncComponent(() =>
	import('./Pages/Routine/Routine').then(module => module.default)
)
const Payment = asyncComponent(() =>
	import('./Pages/TeacherAndAffiliateDashboard/Payment').then(module => module.default)
)
// const QuizResult = asyncComponent(() =>
// 	import('./Pages/Quiz/QuizResult').then(module => module.default)
// )

const Examlist = asyncComponent(() =>
	import('./Pages/Exam/Examlist').then(module => module.default)
)
const PublicExam = asyncComponent(() =>
	import('./Pages/Quiz/PublicExam/PublicExam').then(module => module.default)
)
const PublicExamResult = asyncComponent(() =>
	import('./Pages/Quiz/PublicExam/PublicExamResult').then(module => module.default)
)
const ExamSubcriptions = asyncComponent(() =>
	import('./Pages/Exam/ExamSubcriptions').then(module => module.default)
)
const LiveClasses = asyncComponent(() =>
	import('./Pages/LiveClasses').then(module => module.default)
)
const CatalogCourseLiveClasses = asyncComponent(() =>
	import('./Pages/Course/CatalogCourse/LiveClass/LiveClasses').then(module => module.default)
)
const LiveClassSubcriptions = asyncComponent(() =>
	import('./Pages/LiveClass/LiveClassSubcriptions').then(module => module.default)
)
const WebliveClass = asyncComponent(() =>
	import('./Pages/LiveClass/WebliveClass').then(module => module.default)
)
// const PlayWebFreeVideo = asyncComponent(() =>
// 	import('./Pages/App/PlayWebFreeVideo').then(module => module.default)
// )
const AppSendOtp = asyncComponent(() =>
	import('./Pages/AppAuth/AppSendOtp').then(module => module.default)
)
const AppRegister = asyncComponent(() =>
	import('./Pages/AppAuth/AppRegister').then(module => module.default)
)
const AppLogin = asyncComponent(() =>
	import('./Pages/AppAuth/AppLogin').then(module => module.default)
)
const AppUserChangePassword = asyncComponent(() =>
	import('./Pages/AppAuth/AppUserChangePassword').then(module => module.default)
)
const AppVerifyOtp = asyncComponent(() =>
	import('./Pages/AppAuth/AppVerifyOtp').then(module => module.default)
)
// const Appliveclass = asyncComponent(() =>
// 	import('./Pages/App/Appliveclass').then(module => module.default)
// )
// const AppExam = asyncComponent(() =>
// 	import('./Pages/App/mathjaxPreview/AppExam').then(module => module.default)
// )
// const QuizresultApp = asyncComponent(() =>
// 	import('./Pages/App/QuizresultApp').then(module => module.default)
// )
const PageNotFound = asyncComponent(() =>
	import('./Pages/PageNotFound/PagenotFound').then(module => module.default)
)
const PaymentSuccess = asyncComponent(() =>
	import('./Pages/PaymentSuccess').then(module => module.default)
)
const Footer = asyncComponent(() =>
	import('./components/Footer/Footer').then(module => module.default)
)

function Main() {
	const authToken = getCookie('auth-token')
	if (authToken && authToken !== '') {
		localStorage.setItem('Token', authToken)
		deleteCookie('auth-token', '/', '.rootsedulive.com')
	}
	return (
		<BrowserRouter>
			<Appbar />
			<FormDialog />
			<Switch>
				<Route exact path="/" component={LandingPage} />
				<PrivateRoute exact path="/home" component={HomeIndex} />
				<Route exact path="/payment/success" component={PaymentSuccess} />
				<Route exact path="/course/:id" component={CourseDetails} />
				<Route exact path="/catalogCourse/:id" component={CatalogCourseDetails} />
				<Route exact path="/setGoal" component={SetGoal} />
				<Route exact path="/dashboard" component={Dashboard} />
				<Route exact path="/subscription/:id" component={SubscriptionBuy} />
				<Route exact path="/subscription" component={Subscription} />
				<Route exact path="/myaccount" component={MyAccount} />
				<Route exact path="/recordedClass" component={RecordedClass} />
				<Route exact path="/free-course" component={FreeCouese} />

				<Route exact path="/live-class/subscription" component={LiveClassSubcriptions} />
				<Route exact path="/live-class" component={LiveClasses} />
				<Route exact path="/catalog-course/liveClasses/:id" component={CatalogCourseLiveClasses} />
				<Route exact path="/web-live-class" component={WebliveClass} />
				<Route exact path="/refund-policy" component={RefundPolicy} />
				<Route exact path="/privacy-policy" component={PrivacyPolicy} />
				<Route exact path="/legal" component={Legal} />


				<Route exact path="/exam/list" component={Examlist} />
				<Route exact path="/exam/subscription" component={ExamSubcriptions} />
				<Route exact path="/public-exam/result/:examId" component={PublicExamResult} />
				<Route exact path="/public-exam/:examId" component={PublicExam} />
				<Route exact path="/test/:course_id?/:exam_id?/:subscription_id?/:isFree?" component={Quiz} />
				<Route exact path="/exam-test/result/:id" component={QuizResult} />
				<Route exact path="/affiliate-dashboard" component={Payment} />
				<Route exact path="/me/routine" component={Routine} />
				<Route exact path="/subject" component={SubjectList} />
				<Route exact path="/me/dashboard" component={MyDashboard} />

				<Route exact path="/app/test/questions/:course_ID?/:exam_ID?/:subscription_ID?/:token?/:isCatalogCourse?" component={AppExam} />
				<Route exact path="/app/test/result/:course_ID?/:exam_ID?/:subscription_ID?/:token?/:isCatalogCourse?" component={QuizresultApp} />
				<Route exact path="/app/live-class/:course_ID?/:live_ClassId?/:subscription_ID?/:token?" component={Appliveclass} />
				<Route exact path="/app/play-free-video/:class_ID?/:token?" component={PlayWebFreeVideo} />
				<Route exact path="/app/written-exam/questions/:courseId?/:examId?/:subscriptionId?/:token?" component={WrittenExam} />
				<Route exact path="/app/notice-details/:id" component={Notice} />

				<Route exact path="/phone/login" component={AppLogin} />
				<Route exact path="/phone/register" component={AppRegister} />
				<Route exact path="/phone/send-otp" component={AppSendOtp} />
				<Route exact path="/phone/verify-otp" component={AppVerifyOtp} />
				<Route exact path="/phone/change-password" component={AppUserChangePassword} />
				<Route exact path="*" component={NotFound} />
			</Switch>
			<Footer />
		</BrowserRouter>
	);
}
export default Main;
