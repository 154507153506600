export const actionTypes = {
    Success: "success",
    Danger: "error",
    Warning: "warning",
    Info: "info",
    Close : "close"
  };
  
  const initialToastState = {
    show: false,
    message: "",
    type : ""
  };
  
  const toaster = (state = initialToastState, action) => {
    switch (action.type) {
        case actionTypes.Success: {  
            return { show: true, message: action.payload, type: actionTypes.Success };
        }
        case actionTypes.Danger: {
            return { show: true, message: action.payload, type: actionTypes.Danger };
        }
        case actionTypes.Info: {
            //const { message } = action.payload;
            return { show: true, message: action.payload, type: actionTypes.Info };
        }
        case actionTypes.Close: {
            //const { message } = action.payload;
            return { show: false, message: action.payload, type: actionTypes.Info };
        }
        default:
            return state;
    }
};
export default toaster;