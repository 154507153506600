import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

export default function NoticeLoading() {
  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ p: 1 }}>
        <Skeleton width={300} variant="text" sx={{ fontSize: "1rem" }} />
      </Stack>
      <Stack spacing={2} sx={{ p: 1 }}>
        <Skeleton variant="rectangular" width={300} height={100} />
      </Stack>
    </React.Fragment>
  );
}
