import React, { useState, useEffect } from "react";
import axios from "../../axios";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import "./style.css";
import {useDispatch} from "react-redux";
import {Danger} from '../../redux/action/toaster';
import CardSkeleton from "../../components/Skeleton/CardSkeleton";

function PlayWebFreeVideo() {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [videoID, setVideoID] = useState();
  const [server, setServer] = useState();

  const params = new URLSearchParams(window.location.search);
  var class_ID = params.get('class_ID');
  var token = params.get('token');


  useEffect(() => {

    const url = `/my-courses/free-video?classId=${class_ID}`
    const fetchData = async () => {
      try {

        const response = await axios.get(url, {
          headers: {
            "x-auth-token": token,
            'Content-Type': 'application/json',
          }
        });
        const { server, videoUrl } = response.data
        const videoParams = videoUrl.split('/');
        
        setServer(server)
        if (server == 'youtube') {
          const videoCode = videoParams.pop();
          setVideoID(`https://www.youtube.com/embed/${videoCode}`)


        } else if (server == 'vimeo') {
          var videoCode = "";
          var hashCode = "";
          // Two types of video url in vimeo, with or without hashCode.
          // https://vimeo.com/xxxx
          // https://vimeo.com/xxxx/yyyy
          if(videoParams.length > 4) {
            videoCode = videoParams[videoParams.length - 2];
            hashCode = videoParams[videoParams.length - 1];
            setVideoID(`https://player.vimeo.com/video/${videoCode}?h=${hashCode}`)
          } else {
            videoCode = videoParams[videoParams.length - 1];
            setVideoID(`https://player.vimeo.com/video/${videoCode}`)
          }

        }

        setLoading(false)

      } catch (error) {
        setLoading(false)
        dispatch(Danger(`${error.response.data}`))
      }
    };

    fetchData();
  }, []);


  return (
    <>
      {
          loading ? <div style={{width:'100vw', padding:'56.25% 0 0 0', backgroundColor:'black'}}> <CardSkeleton /> </div>
          :
          <div style={{ width: '100%', height: '100vh', backgroundColor: 'black' }}>
            <div className="main-container">
              <div className="event-container-play-video">
                <div className="event-body">
                  {
                    server == 'vimeo' ? <iframe
                      src={videoID}
                      frameBorder="0"
                      allowFullScreen
                    >
                    </iframe> :
                      <iframe
                        src={videoID}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      >
                      </iframe>
                  }

                </div>
              </div>
            </div>
          </div>
      }

    </>
  );
}

export default PlayWebFreeVideo;