const FETCH = 'FETCH';
const DECREMENT = 'DECREMENT';
const INITIAL_STATE = {
    count: 0,
};
const counter = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH:
            return {
                ...state, data:action.payload, status :'fetch data works'
            };
        case DECREMENT:
            return {
                ...state, count: state.count - 1,
            };
        default: return state;
    }
};
export default counter;