//AUTHENTICATION CONSTANTS
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const REGISTRACTION = 'REGISTRACTION'
export const SEND_OTP = 'SEND-OTP'
export const CHANGE_PASSWORD = 'CHANGE-PASSWORD'
export const VERIFY_OTP = 'VERIFY-OTP'
export const SWITCH_AUTH_PAGES = 'SWITCH-AUTH-PAGES'
export const TOGGLE_AUTH_MODAL = 'TOGGLE-AUTH-MODAL'
export const VERIFY_INSTITUTION = 'VERIFY_INSTITUTION'
export const HIDE_AUTH_MODAL = 'HIDE-AUTH-MODAL'

export const LOADING = 'LOADING'