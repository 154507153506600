import  React,{useEffect} from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSelector , useDispatch} from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Close} from "../../redux/action/toaster";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const actionTypes = {
  Success: "success",
  Danger: "error",
  Warning: "warning",
  Info: "info",
  Close : "close"
};

export default function SnackBar() {
 
  const dispatch = useDispatch()
  const toaster = useSelector(state=>state.toaster)
  
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch(Close(""))
  };
  const [state, setState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });
  const { vertical, horizontal} = state;



  return (
    <div>
      <Snackbar
        style={{ zIndex: '9999999999999' }}
        anchorOrigin={{ vertical, horizontal }}
        open={toaster.show}
        onClose={handleClose}>

        <Alert style={{ zIndex: '9999999999' }} onClose={handleClose} severity={`${toaster.type}`} sx={{ width: '100%' }}>
          {toaster.message}
        </Alert>


      </Snackbar>
    </div>
  );
}
