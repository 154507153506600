import React from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

export default function QuestionLoading() {
    return (
        <React.Fragment>
            <Stack spacing={2} sx={{ p: 1 }}>
                <Skeleton width={300} variant="text" sx={{ fontSize: '1rem'}} />
                <Skeleton width={300} variant="text" sx={{ fontSize: '1rem'}} />
                <Skeleton width={300} variant="text" sx={{ fontSize: '1rem'}} />
                <Skeleton width={300} variant="text" sx={{ fontSize: '1rem'}} />
            </Stack>

            <Box justifyContent="center"
                alignItems="center" sx={{ width: '100%' }}>
                {[0, 1, 2, 3, 4, 5].map((item, index) =>
                    <Stack sx={{ p: 2 }} key={index}>
                        <Skeleton variant="rectangular" width={"100%"} height={80} />
                        <Divider sx={{ width: '100%', mt: 3 }} />
                    </Stack>
                )}
            </Box>

        </React.Fragment>
    )
}
