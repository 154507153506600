import * as React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Card, Avatar, Chip, ListItemAvatar } from "@mui/material";
import "./leaderboard.scss";
/* @MUI COMPONENT */
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Container } from "react-bootstrap";
import { deepPurple } from "@mui/material/colors";

function SimpleDialog(props) {
  const { onClose, toppers, open, myRankData, myRank, handleClose, scroll } =
    props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  var ismyRankOnTopList = false;
  toppers.map((item) => {
    if (item.user && item.user._id === myRankData?.user?._id) {
      ismyRankOnTopList = true;
    }
  });

  return (
    <Dialog
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h3"
            component="div"
            align="center"
          >
            Leaderboard
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {toppers?.map((item, index) => (
            <>
              <Container maxWidth="sm">
                <List
                  sx={item?.user && item?.user._id === myRankData?.user?._id ? {
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "#0066CC", color: 'white',
                    borderRadius: '10%',
                    p: 2
                  } : {
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    P: 2
                  }}
                >
                  <Typography sx={index < 3 && item?.user._id !== myRankData?.user?._id ? { color: '#0066CC', fontWeight: 700 } :
                    item?.user._id === myRankData?.user?._id ? { color: 'white', fontWeight: 700 } : { color: 'black', fontWeight: 700 }}
                    variant="h6" gutterBottom align="flex-start"> {index + 1}.
                  </Typography>
                  <ListItem sx={{mt : -2}} alignItems="flex-start">
                    <ListItemAvatar>
                      {item?.user?.avatar ? (
                        <Avatar
                          sx={{
                            background: "#2979ff",
                            color: "white",
                          }}
                          alt=""
                          src={
                            `https://cdn.rootsedulive.com/` + item?.user?.avatar
                          }
                        />
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor: deepPurple[500]
                          }}
                        >
                          {item?.user?.name[0].toUpperCase()}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={index < 3 && item?.user._id !== myRankData?.user?._id ? { color: '#0066CC', fontWeight: 700 } :
                              item?.user._id === myRankData?.user?._id ? { color: 'white', fontWeight: 700 } : { color: 'black', fontWeight: 700 }}
                            variant="h6">
                            {item?.user.name}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body1"
                            sx={item?.user && item?.user._id === myRankData?.user?._id ? { color: 'white' } : { color: "text.primary", display: "inline" }}
                          >
                            {item?.user?.institution}
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <Typography sx={item?.user && item?.user._id === myRankData?.user?._id ? { color: 'white' } : { color: 'black' }} gutterBottom variant="body1">Marks: {item?.obtainedMarks ? `${(item?.obtainedMarks).toFixed(2)}` : "N/A"}</Typography>
                            <Typography sx={item?.user && item?.user._id === myRankData?.user?._id ? { color: 'white' } : { color: 'black' }} gutterBottom variant="body1">Time: {item?.avgTimePerQuestionInSec ? `${(item?.avgTimePerQuestionInSec).toFixed(2)}s/question` : "N/A"}</Typography>
                          </Box>
                        </React.Fragment>
                      }
                    />

                    {/* {item?.user.name}
                    {item?.user?.institution} */}
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              </Container>
            </>
          ))}

          {
            ismyRankOnTopList === false && myRank &&
            <Container maxWidth="sm">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "#0066CC",
                  color: 'white',
                  borderRadius: '10%',
                  p : 1
                }}
              >
                <Typography
                  sx={{ color: 'white', fontWeight: '700' }}
                  variant="h6">
                  {myRank}.
                </Typography>

                <ListItem sx={{mt : -2}} alignItems="flex-start">
                  <ListItemAvatar>
                    {myRankData?.user?.avatar ? (
                      <Avatar
                        sx={{
                          background: "#2979ff",
                          color: "white",
                        }}
                        alt=""
                        src={
                          `https://cdn.rootsedulive.com/` + myRankData?.user?.avatar
                        }
                      />
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: deepPurple[500]
                        }}
                      >
                        {myRankData?.user?.name[0].toUpperCase()}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          sx={{ color: 'white', fontWeight: 700 }}
                          variant="h6">
                          {myRankData?.user.name}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="white"
                        >
                          {myRankData?.user?.institution}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                          <Typography gutterBottom variant="body1" color={"white"}>Marks: {myRankData?.obtainedMarks ? `${(myRankData?.obtainedMarks).toFixed(2)}` : "N/A"}</Typography>
                          <Typography gutterBottom variant="body1" color={"white"}>Time: {myRankData?.avgTimePerQuestionInSec ? `${(myRankData?.avgTimePerQuestionInSec).toFixed(2)}s/question` : "N/A"}</Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </Container>
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function QuizTopperList({ toppers, myRankData, myRank }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(toppers);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Chip
        onClick={handleClickOpen("paper")}
        className="hover-item"
        label="Leaderboard"
        color="success"
        sx={{ width: "100%", mb: 1 }}
      />
      <SimpleDialog
        toppers={toppers}
        myRankData={myRankData}
        myRank={myRank}
        open={open}
        onClose={handleClose}
        scroll={scroll}
      />
    </>
  );
}
