const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
const LOADING = 'LOADING';
const INITIAL_STATE = {
    subscriptions : [],
    loading : true,
    goalID :'',
    mySubscriptions:[]
};
const subscription = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTIONS:
            return {
                ...state, 
                subscriptions: action.payload.subscriptions,
                mySubscriptions : action.payload.mySubscriptions, 
                loading: false , 
                goalID:action.payload.goalID
            };
        case LOADING:
            return {
                ...state, loading: true
            };
        default: return { ...state, loading: false };
    }
};
export default subscription;