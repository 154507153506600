import React from 'react'
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import {sendOTP} from '../../redux/action/auth';
import { Danger } from '../../redux/action/toaster';

export default function SendOTP() {

    const dispatch = useDispatch()
    //const {phoneOrEmail}  = useSelector(state => state.auth)
    const [phone, setPhone] = React.useState("");

    const handleChange = (event) => {
        setPhone(event.target.value);
    };

    const handleSubmit = () => {
        if(phone.length !== 11) return dispatch(Danger("Please enter a valid phone number"))
        dispatch(sendOTP(`88${phone}`))
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>

                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount"> Phone</InputLabel>
                    <OutlinedInput
                        name="phone"
                        id="outlined-adornment-amount"
                        value={phone}
                        onChange={handleChange}
                        startAdornment={<InputAdornment position="start"><PhoneInTalkIcon /></InputAdornment>}
                        label="Phone"
                    />
                </FormControl>

                <FormControl style={{ width: '100%' }} sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <Button onClick={handleSubmit} variant="contained">Send OTP</Button>
                </FormControl>
            </div>
        </Box>
    )
}
