import { combineReducers } from 'redux';
import counter from './reducer/counter';
import subscription from "./reducer/subscription";
import subject from "../redux/reducer/subject";
import toaster from "../redux/reducer/toaster";
import goals from './reducer/goals';
import auth from "./reducer/auth";

const rootReducer = combineReducers({
    counter: counter,
    subscription: subscription,
    subject: subject,
    toaster: toaster,
    goals: goals,
    auth : auth
});
export default rootReducer;