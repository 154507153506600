import { CardContent, CardHeader, Typography } from "@mui/material";
import Card from "@mui/material/Card";

export default function RefundPolicy() {
  return (
    <div>
      <Card sx={{ paddingLeft: 2 }}>
        <CardContent sx={{ maxWidth: 1200 }}>
          <h5 id="cancellation--refund-policy">
            Cancellation &amp; Refund Policy
          </h5>
          <h6 id="what-is-a-refund">What is a Refund?</h6>
          <ul>
            <li>
              When a learner is unable to access the course due to technical
              issues in the app/website, does not receive resources as promised
              in the course, or mistakenly purchases a course instead of another
              course from ROOTs Edu, the user can request a refund. This request
              will go through a verification process.
            </li>
          </ul>
          <h6 id="how-to-request-a-refund">How to Request a Refund</h6>
          <ul>
            <li>
              <p>
                If you have paid for an individual course, a bundle course, or a
                subscription, you can request a refund within 24 hours of your
                payment.
              </p>
            </li>
            <li>
              <p>To request a refund, follow these steps on a computer:</p>
              <ul>
                <li>
                  Call 01789114390 (9 am to 8 pm, except weekends and government
                  holidays) or inbox our Facebook Page:{" "}
                  <a href="https://www.facebook.com/rootsedulive">
                    https://www.facebook.com/rootsedulive
                  </a>{" "}
                  and state the email address/phone number you used to sign up
                  and purchase the course.
                </li>
              </ul>
            </li>
            <li>
              <p>
                Refund requests are only considered valid if contacted and
                informed within 24 hours of purchase, clearly specifying your
                email address and phone number used during registration.
              </p>
            </li>
          </ul>
          <p>
            <strong>Note:</strong> Refunds do not apply to e-books. Refunds for
            subscription-based courses will not be applicable if a refund
            request is submitted after the class has started for that course.
          </p>
          <ul>
            <li>
              Refunds shall be made to the medium through which the customer
              made the payment or in the form of a voucher/wallet money within
              7-14 working days of successful processing and approval of the
              refund request by ROOTs Edu. This confirmation will be emailed to
              the user.
            </li>
          </ul>
          <h6 id="when-will-the-refund-not-be-applicable">
            When Will the Refund Not Be Applicable?
          </h6>
          <ul>
            <li>
              If the complaint or refund request is submitted after 24 hours
              from the date of purchase.
            </li>
            <li>
              If the purchased course is already an exchanged course based on a
              prior refund request.
            </li>
            <li>
              If you have violated any of the company-specified community
              guidelines.
            </li>
            <li>
              If you complete an activity (play a video, try a quiz or test,
              download materials of the specific course) and proceed with the
              course, you will not be eligible for a refund.
            </li>
            <li>
              If you complete more than 5 premium/paid videos before requesting
              a refund, you will not be eligible for a refund.
            </li>
            <li>If you have purchased an e-book.</li>
            <li>
              If you have purchased a subscription-based course and the class
              has started for that course.
            </li>
          </ul>
          <h6 id="when-will-the-refund-be-applicable">
            When Will the Refund Be Applicable?
          </h6>
          <ul>
            <li>
              In case of a wrong purchase or if the customer is willing to
              purchase another course, a transfer to another course can be done
              by providing a voucher/wallet money. If the price of the new
              course is:
              <ul>
                <li>
                  Higher than the purchased course, the user has to pay the
                  additional amount to ROOTs Edu by the preferred payment
                  method.
                </li>
                <li>
                  Lower than the purchased course, the user will get a refund of
                  the additional amount from ROOTs Edu by the payment medium
                  through which the course was purchased.
                </li>
              </ul>
            </li>
            <li>
              If ROOTs Edu fails to provide the resources as promised in the
              course.
            </li>
            <li>
              Mistakenly purchased a live class instead of a recorded class and
              vice-versa.
            </li>
          </ul>
          <h6 id="what-happens-when-you-request-a-refund">
            What Happens When You Request a Refund?
          </h6>
          <ul>
            <li>
              Once a refund request has been submitted, your enrolled course
              will be temporarily locked. ROOTs Edu shall notify you through
              SMS/email/support inbox if the refund request has been accepted
              within 3 working days of receiving the request. After a refund
              request has been accepted, you will be unenrolled from the course
              and your progress will be removed. If you wish to purchase the
              course again, you will have to start from the beginning.
            </li>
            <li>
              It can take up to 7-14 working days for funds to return to your
              account after a requested refund. If it has been longer than 14
              working days, please call 01789114390 (9 am to 8 pm, except
              weekends and government holidays).
            </li>
            <li>
              Once the refund has been processed, you will receive a
              confirmation email/SMS/message in the support inbox to your
              registered email/phone number/social media ID.
            </li>
          </ul>
          <p>
            ROOTs Edu reserves the right to change the Terms &amp; Conditions
            from time to time and on a case-by-case basis.
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
