import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Container } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import axios from "../../axios";
// import MathJax from 'react-mathjax-preview'
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { Danger } from "../../redux/action/toaster";
import QuizTopperList from "./QuizTopperList";
import { FaMedal } from 'react-icons/fa';
import { RiMedal2Fill } from "react-icons/ri";
import { FcAlarmClock } from "react-icons/fc";
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { BiHash } from "react-icons/bi"
import { TiTickOutline } from 'react-icons/ti';
import { ImCross } from "react-icons/im";
import AnswerBox from "./component/AnswerBox";

export default function QuizResult(props) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [examRankData, setExamRankData] = useState({});
  const mathJaxRef = useRef()

  var {
    questions,
    selectAnswer,
    title,
    noOfQuestion,
    marksPerQuestion,
    negativeMarksPerQuestion,
    durationInSec,
  } = props.location.state.result;

  var isCatalogCourse = props.location.state.result?.isCatalogCourse

  var { course_id, subscription_id, exam_id } = props.location.state;

  var total_correct_answer = 0;
  var total_wrong_answer = 0;
  var correct_index = [];
  var wrong_index = [];
  var total_questions = questions.length;
  if (questions.length > 0) {
    questions.map((item) => {
      selectAnswer.map((item2) => {
        if (item.serialNo == item2.question + 1) {
          if (item.correctAns == item2.selected) {
            total_correct_answer += 1;
            correct_index.push(item.serialNo);
          } else {
            total_wrong_answer += 1;
            wrong_index.push({
              serial: item.serialNo,
              selected: item2.selected,
            });
          }
        }
      });
    });
  }

  var obtainedMarks =
    marksPerQuestion * total_correct_answer -
    total_wrong_answer * negativeMarksPerQuestion;
  var timeCount = localStorage.getItem("Quiz_Completion_Time");
  const { hours, minutes, seconds } = JSON.parse(timeCount);

  useEffect(() => {
    getExamRank();
  }, []);

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  const getExamRank = async () => {
    const url = `/mcq-exam-results/view-rank?subscriptionId=${subscription_id}&courseId=${course_id}&examId=${exam_id}&isCatalogCourse=${isCatalogCourse}`;
    const response = await axios.get(url, {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json",
      },
    });
    setExamRankData(response?.data);
    setLoading(false);
  };

  if (questions.length == undefined || questions.length == 0) {
    return (
      <Container>
        <Row
          className="d-flex justify-content-center"
          style={{ paddingTop: "100px", marginBottom: "20px", width: "100%" }}
        >
          <Row
            style={{ width: "100%" }}
            className="quiz-result-main d-flex justify-content-center align-items-center"
          >
            Quiz Not Found
          </Row>
        </Row>
      </Container>
    );
  }


  return (
    <div className="table-border-wrap">
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <Container>
          <Helmet>
            <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
            <script
              ref={mathJaxRef}
              id="MathJax-script"
              async
              src="https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js"
            >

            </script>
          </Helmet>
          <div>
            <div className="quiz-result-header">
              <h2>{title}</h2>
              <div className="result-info">
                <div>
                  <h5><BsFillQuestionCircleFill />{" "}Total Questions: {noOfQuestion} </h5>
                  <h5><BiHash />{" "}Total Marks: {noOfQuestion * marksPerQuestion}</h5>
                  <h5 style={{ color: 'green' }}> <span style={{ fontSize: '30px' }}><TiTickOutline /></span>{marksPerQuestion} Mark/Question</h5>
                  <h5 style={{ color: 'red' }}><span style={{ fontSize: '20px' }}><ImCross /></span>{""} {negativeMarksPerQuestion}/Wrong
                    Answer</h5>
                  <h5>
                    <FcAlarmClock /> {""}
                    {(durationInSec / 60).toFixed(2)} Minutes
                  </h5>
                  <div className="position-display">
                    <h5 style={{ textAlign: 'center', marginTop: '7px' }}>Obtained Marks: {obtainedMarks}</h5>
                  </div>
                </div>

                <div>
                  <QuizTopperList
                    toppers={examRankData.toppers}
                    myRankData={examRankData?.userDetails}
                    myRank={examRankData?.userRank}
                  />
                  <Row>
                    <Col style={{ margin: '15px 0px' }} md={6} xs={12}>
                      <h5 style={{ textAlign: 'center' }}><RiMedal2Fill />&nbsp;Exam Rank: {examRankData?.userRank ? examRankData?.userRank : "N/A"}</h5>
                    </Col>
                    <Col style={{ margin: '15px 0px' }} md={6} xs={12}>
                      <h5 style={{ textAlign: 'center' }}>
                        <FaMedal style={{ color: 'green' }} />&nbsp;Highest Marks:{" "}
                        {examRankData.toppers[0]
                          ? examRankData.toppers[0].obtainedMarks
                          : null}
                      </h5>
                    </Col>
                  </Row>

                  <div style={{ marginTop: '10px' }} className="result-break-down">
                    <div >
                      <div className="result-outlined-box">
                        <h1 className="number-inside-outline" style={{ textAlign: "center", color: "green" }}>
                          {total_correct_answer}
                        </h1>

                        <CheckCircleIcon
                          style={{
                            backgroundColor: "white",
                            color: "green",
                            position: "absolute",
                            top: "-10px",
                            right: "-12px",

                          }}
                        />
                      </div>
                      <p className="badge-text-footer mtr">Correct</p>
                    </div>

                    <div >
                      <div className="result-outlined-box">
                        <h1 className="number-inside-outline" style={{ textAlign: "center", color: "crimson" }}>
                          {total_wrong_answer}
                        </h1>

                        <CancelIcon
                          style={{
                            backgroundColor: "white",
                            color: "red",
                            position: "absolute",
                            top: "-10px",
                            right: "-12px",

                          }}
                        />
                      </div>
                      <p className="badge-text-footer mtr">Wrong</p>
                    </div>
                    <div>
                      <div className="result-outlined-box">
                        <h1 className="number-inside-outline" style={{ textAlign: "center", color: "blue" }}>
                          {Math.abs(
                            total_correct_answer +
                            total_wrong_answer -
                            questions.length
                          )}
                        </h1>

                        <InfoRoundedIcon
                          style={{
                            backgroundColor: "white",
                            color: "blue",
                            position: "absolute",
                            top: "-10px",
                            right: "-12px",

                          }}
                        />
                      </div>
                      <p
                        style={{ padding: "10px 0px" }}
                        className="badge-text-footer mtr "
                      >
                        Unanswered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* PErcentage showing card*/}


          {/*  SHOW ALL ANSWER */}
          <Row
            style={{ width: "100%" }}
            className="quiz-result-main d-flex justify-content-center align-items-center"
          >

            <Row
              style={{ width: "100%" }}
              className="d-flex justify-content-center align-items-center"
            >
              {questions?.map((item, index) => (
                <Paper rounded={true} elevation={4} square component={Col} style={{ margin: '15px 0px', borderRadius: '5px' }} md={12}>
                  <AnswerBox key={index} wrong_index={wrong_index} correct_index={correct_index} item={item} index={index} />
                </Paper>
              ))}
            </Row>

          </Row>
        </Container>
      )}
    </div>
  );
}
