import { CardContent, CardHeader, Typography } from "@mui/material";
import Card from "@mui/material/Card";

export default function Legal() {
  return (
    <>
      <Card sx={{ paddingLeft: 2 }}>
        <CardContent sx={{ maxWidth: 1200 }}>
          <h5 id="terms-and-conditions">TERMS AND CONDITIONS</h5>
          <p>
            These Terms &amp; Conditions (“Terms”) govern (a) the use of our
            website{" "}
            <a href="http://www.rootsedulive.com">www.rootsedulive.com</a>{" "}
            (“Website”), our applications (“Application”), or any products or
            services in connection with the Application, Website, or products
            (“Services”), or (b) any modes of registration or usage of products,
            including through SD cards, tablets, or other storage/transmitting
            devices. These terms are between ROOTs Edu (“Company/We/Us/Our”) and
            its users (“User/You/Your”).
          </p>
          <h6 id="acceptance-of-terms">Acceptance of Terms</h6>
          <p>
            Please carefully read these Terms and our Privacy Policy prior to
            registering, using the Application, Website, or Services. Any
            discrepancies between the Terms and other policies on the
            Application, Website, or Services are settled in favor of the Terms.
            By accessing the Application, Website, or Services, you accept and
            agree to be legally bound by these Terms.
          </p>
          <h6 id="registration-and-subscription">
            Registration and Subscription
          </h6>
          <p>
            Our Application, along with all our Services and products, can only
            be accessed through a registration/subscription.
          </p>
          <h6 id="proprietary-information">Proprietary Information</h6>
          <p>
            The software, text, images, graphics, video, script, and audio,
            including any other information, content, material, trademarks,
            service marks, trade names, and trade secrets contained in the
            Application, Website, Services, and products, are the Company’s
            proprietary property (“Proprietary Information”). Permission to
            reproduce, reuse, modify, and distribute Proprietary Information may
            not be granted without first obtaining permission from the Company.
            Users of the Application, Website, or Services may not claim any
            ownership of the intellectual property rights of the Company. You
            can control the contents, but the Company always maintains ownership
            of the information, content, and all intellectual property on that
            medium.
          </p>
          <p>
            While using the Website, you may encounter materials that belong to
            other people. Prior consent was obtained from the subject, and all
            rights to the content belong to the third party. Furthermore, you
            accept that all intellectual property, including trademarks,
            copyrights, logos, and service marks, remains the property of the
            respective third party, and you are not entitled to use it without
            the third party’s permission.
          </p>
          <h6 id="permitted-use">Permitted Use</h6>
          <p>
            It is prohibited to use our products, Services, Website, and
            Application for any purpose other than personal or non-commercial.
            The following rules apply to your use of the Application, Website,
            and our Services for personal and non-commercial purposes:
          </p>
          <ol>
            <li>
              You may not copy, modify, translate, display, distribute, or
              perform any information or software obtained from the Application,
              Website, or Services/Products, or remove any copyright, trademark
              registration, or other proprietary notices from the contents of
              the Application, Website, or Services/Products.
            </li>
            <li>
              You are prohibited from using the Application, Website, and our
              products and Services for commercial purposes, including but not
              limited to selling, advertising, or promoting the Application or
              any of our products or Services, asking for contributions or
              donations, or using public forums for commercial purposes.
            </li>
          </ol>
          <h6 id="prohibited-conduct">Prohibited Conduct</h6>
          <p>
            The following acts are strictly prohibited when using our Services:
          </p>
          <ul>
            <li>
              Make available any deceptive, unlawful, hazardous, abusive,
              torturous, defamatory, libelous, vulgar, obscene, child
              pornographic, loud, lascivious, unlawful, intrusive, hateful,
              racial, ethnic, or otherwise objectionable content.
            </li>
            <li>
              Threatening, stalking, and/or harassing another person, as well as
              inciting another person to conduct violence.
            </li>
            <li>
              Passing on information that encourages illegal acts, results in
              civil liability, or violates any applicable laws, regulations, or
              guidelines.
            </li>
            <li>
              Interfering with another person’s usage or enjoyment of the
              Application/Website/Services.
            </li>
            <li>
              Engaging in making, copying, transmitting, storing, or otherwise
              reproducing any intellectual property protected under copyright
              law or providing others with access to anything that infringes on
              any intellectual property or other proprietary rights of any
              party.
            </li>
            <li>
              Providing anyone with information or anything else that you have
              no right to make available under any law or contract or fiduciary
              duty unless you have control of the rights or have obtained the
              necessary consent to do so.
            </li>
            <li>
              Falsely impersonating any person or entity, or misrepresenting
              your relationship with any person or entity.
            </li>
            <li>
              Transmitting, sharing, or otherwise making available any material
              that contains viruses, trojans, worms, spyware, time bombs,
              cancelbots, or other computer programming routines, code, files,
              or programs that could harm the Application/Services, interests,
              or rights of other users, or limit the functionality of any
              computer software, hardware, or telecommunications.
            </li>
            <li>
              Using the Application or accessing the Website in any way that
              could harm, overload, or crash any of the Application’s or
              Website’s servers or networks.
            </li>
            <li>
              Violating any laws or restrictions that pertain to your use of the
              Application/Website/Services/products, breaking the rules of the
              networks connected to the Application/Website/Services/Products,
              or doing anything you are banned from doing by these Terms.
            </li>
            <li>
              Disrupting, interfering with, or otherwise causing harm to the
              security of, or gaining unauthorized access to, accounts, servers,
              or networks connected to or accessible through the
              Application/Website/Services/Products or any affiliated or related
              sites.
            </li>
            <li>
              Disrupting access to the Application/Website/Services/Products or
              any of our other sites or systems, or participating in attacks
              such as denial of service.
            </li>
            <li>
              Accessing, acquiring, copying, or monitoring the
              Application/Website/Services/Products by any means not made
              explicitly available through the
              Application/Website/Services/Products, including but not limited
              to manual processes, devices, programs, algorithms, methodologies,
              or similar or equivalent devices, tools, or manual activities.
            </li>
            <li>Changing or altering any element of the Services.</li>
            <li>
              Using the Services in contradiction to these Terms or to the
              extent prohibited by law, regulation, or generally accepted
              practices or standards in the relevant country.
            </li>
            <li>
              Avoiding any of the terms in the Agreement for the use of the
              Application/Website/Services/products.
            </li>
          </ul>
          <h6 id="license-grant">License Grant</h6>
          <p>
            You grant us a worldwide, non-exclusive, royalty-free license (with
            the right to sublicense) to use, copy, reproduce, process, adapt,
            modify, publish, transmit, display, and distribute your content that
            you submit to or through the Services, as well as to associate your
            content with you, unless prohibited. You provide permission for
            others to use your content in the same manner that any other content
            found on the Services may be used. The Services may allow other
            users to make copies of your content in line with these Terms. You
            will still be able to access your content and information if you
            deactivate your account.
          </p>
          <h6 id="accuracy-and-updates">Accuracy and Updates</h6>
          <p>
            Every effort has been taken to ensure that the information provided
            is current, correct, and clear in the production of the
            Application/Website/Services/Products and contents therein. While
            unintentional mistakes may occur, the Company expressly disclaims
            any responsibility for the veracity of information that may be in
            the application. Users’ input is encouraged to make the application
            and its contents error-free and user-friendly. The Company also
            retains the right to make any modifications or revisions to the
            contents at any time, without advance notice. Neither the Company
            nor any third parties give any guarantees regarding the accuracy,
            timeliness, or suitability of the information and materials found or
            offered on the Application/Website/Services/Products for any
            specific purpose. You understand that there might be flaws in the
            information and materials we’re providing, and we make no promises
            about their accuracy.
          </p>
          <h6 id="educational-content">Educational Content</h6>
          <p>
            Our website serves as a resource center for collated educational
            content and relevant sources. We take no duty for the accuracy,
            completeness, or use of such information, which is provided on an
            “as is” basis. We are not liable for ensuring the site’s information
            is updated or accurate. Any inaccuracies in the information we
            assemble, which comes from third-party sources, or which is
            unavailable, would not be our responsibility. The site may also
            provide hyperlinks to other websites from time to time. These links
            are provided for your information so that you can find further
            details. We do not support the website by using these links. The
            websites we link to are not our responsibility. A link to the site
            may not be created without the explicit written permission of the
            Company.
          </p>
          <h6 id="curriculum-based-materials">Curriculum-Based Materials</h6>
          <p>
            The materials and goods are based on concepts from the curriculum,
            which is outlined for pupils of various classes. The
            Application/Services/Products should not be seen as a replacement
            for traditional schooling; instead, they are designed to complement
            it by providing a way to make complex ideas easier to understand.
            Everything about the subject would remain the same, except the
            definitions and equations. The Company understands that there are
            other ways to present structured curriculum pedagogy, and the
            inclusion of methods in the Application/Services/Products does not
            mean that the Company endorses any of them. The use of our
            Services/Website/Products does not guarantee entry to any academic
            institutions or the success of any exam or achieving any designated
            percentage in any test.
          </p>
          <h6 id="opinions-and-guarantees">Opinions and Guarantees</h6>
          <p>
            Contents of the Application/Services/Website/Products (especially
            those about services which help with administrative tasks) are
            sometimes opinionated. Such opinions will not be the responsibility
            of the Company. The Company also provides no guarantees on the
            quality, content, or completeness of the information and data.
          </p>
          <h6 id="user-generated-content">User-Generated Content</h6>
          <p>
            The Services include interactive content, and we encourage users to
            submit content for publication. This content is subject to editing
            prior to publication. The Company accepts no accountability or
            obligation for any content communicated via this method. The Company
            maintains the right to remove, review, edit, or delete any content
            at its sole discretion. We will not be liable for any user-generated
            content placed on the site, regardless of whether we have approved
            any user-submitted content. It is the responsibility of the user to
            ensure that their content does not infringe on anyone’s copyright.
          </p>
          <h6 id="communications-and-contact">Communications and Contact</h6>
          <p>
            The Company (and its subsidiaries/affiliates) may contact Users
            through SMS, email, and phone calls to inform them about their
            services and products and to seek permission to demonstrate their
            offerings. The User grants permission to the Company (and its
            subsidiaries/affiliates) to contact them via telephone, SMS, and
            email. The User agrees to hold the Company harmless from any
            liability, including financial penalties, damages, and expenses if
            the User’s mobile number is registered with the Do Not Call (DNC)
            database. You will be contacted through various means for marketing
            and educational information once you register, including over the
            phone, SMS, and email.
          </p>
          <h6 id="product-demonstrations">Product Demonstrations</h6>
          <p>
            The Company may, with the user’s consent, assist in a demonstration
            of its products at the requested location. The user understands that
            they have not been misled regarding the products’ quality or other
            features and that they relied solely on their own investigations,
            inspections, and evaluations.
          </p>
          <h6 id="communication-for-service-optimization">
            Communication for Service Optimization
          </h6>
          <p>
            The Company may reach you through your registered mobile number,
            email, or any other contact information you supply to help us better
            provide our services. The User agrees to receive communications and
            messages from the Company and the student via the Services. The
            Company can monitor User/student downloads and use of the
            Application/Services/Products and contents to communicate with the
            User/student in order to improve and optimize the usage of the
            Services. Students must agree to have their doubts about assignments
            cleared by the application’s service, and the application must
            respond to any questions that students have about their coursework.
          </p>
          <h6 id="employee-conduct-and-quality-control">
            Employee Conduct and Quality Control
          </h6>
          <p>
            Even though the Company has made an effort to train its sales and
            service employees for quality control, it offers no warranties or
            assurances about the employees’ ability to do so and is not
            responsible for any malfeasance committed by any of its employees.
            The Company invites User feedback on this matter and reserves the
            right to act as it sees fit.
          </p>
          <h6 id="service-terms-and-fair-use-policy">
            Service Terms and Fair Use Policy
          </h6>
          <p>
            Elements of the Services, including counseling, case management, and
            so on, may be subject to their own terms, conditions, and fair use
            policy. The Company has the authority to set various service
            aspects’ requirements based on its regulations. Subscriptions to the
            app or signing up for services do not guarantee entitlement to all
            that the Company provides. It’s up to the Company to decide who gets
            access to particular services. Our Services may be extended,
            canceled, discontinued, or changed without notice.
          </p>
          <h6 id="compatibility-and-upgrades">Compatibility and Upgrades</h6>
          <p>
            Devices, tablets, instruments, and hardware with particular
            specifications are compatible with the Company’s products and/or
            Services, including the Application and content. The Company is not
            required to deliver operational products and services for any tools
            it doesn’t accept or that are bought from a third party and that
            don’t work with the Company’s merchandise. The Company is free to
            upgrade the device/tablet type as needed.
          </p>
          <h6 id="liability-disclaimer">Liability Disclaimer</h6>
          <p>
            The Company will not be held liable for any loss or damage resulting
            from your use of our products and services, including loss of data
            or impact on processing speed.
          </p>
          <h6 id="shipping-and-returns">Shipping and Returns</h6>
          <ul>
            <li>
              At the time of purchase, you must specify the address where the
              shipment should be delivered.
            </li>
            <li>
              All merchandise must be delivered to the address provided upon
              ordering, and it is prohibited to change the delivery address
              following purchase completion.
            </li>
            <li>
              You must notify us of any change in your mailing address in
              advance of your expected shipping date.
            </li>
            <li>
              If you have any errors in your name or address, your purchase may
              not be delivered.
            </li>
            <li>
              The shipping charges associated with the return of goods damaged
              at the time of delivery will be paid by the Company.
            </li>
            <li>
              For any reason other than the reasons listed above, the User is
              responsible for arranging the return of the canceled product(s)
              and is responsible for the shipping charges.
            </li>
            <li>
              Do not accept any delivery that appears to have been tampered
              with, opened, or damaged.
            </li>
            <li>
              The Company’s products must be returned in their original
              condition. Returns will not be accepted for items that show any
              wear or damage in any way.
            </li>
            <li>
              All requests for product returns must be made within 15 days of
              delivery. Note that refunds will not be accepted beyond 15 days
              from delivery.
            </li>
          </ul>
          <h6 id="account-registration-and-maintenance">
            Account Registration and Maintenance
          </h6>
          <p>
            To use the application and services, you need to register and
            maintain an account, providing your name, mobile number, email
            address, residential address, student class and school, and any
            other necessary information. You are solely responsible for ensuring
            that your account information is accurate, comprehensive, and
            up-to-date. You are also responsible for protecting your account
            information and all activity on your account. To avoid account
            access problems, you should log out at the end of each session and
            inform the Company of any unauthorized access. If a security breach
            or misuse is suspected, the Company may ask you to change your
            password or suspend your account without incurring any liability.
            The Company will not be responsible for any losses or damages you
            incur due to non-compliance with this section. Your ability to use
            your account depends on external factors such as the reliability of
            your internet service provider and network. The Company cannot
            guarantee that you will always have access to the Application and
            shall not be responsible for losses arising from failure to log into
            your account.
          </p>
          <h6 id="eligibility">Eligibility</h6>
          <p>
            Individuals under the age of 18 or those in debt resolution are not
            eligible to register for our products or services. Minors may use
            our products and services only with the consent of their legal
            guardian or parents. If a minor uses the
            Application/Website/Services, it is presumed that they have obtained
            such consent. The Company is not liable for any issues arising from
            misuse of our products or services, including use by a minor.
            Parents or legal guardians must authorize students to use the
            Application, and accurate data must be used. If it is found that a
            user under 18 does not have proper authorization, the Company
            reserves the right to terminate their membership and deny access to
            products or services. The Company does not have a duty to ensure
            users meet the age requirement; users must personally guarantee that
            they meet the necessary qualifications.
          </p>
          <h6 id="user-responsibilities-and-indemnity">
            User Responsibilities and Indemnity
          </h6>
          <p>
            You are responsible for protecting, reimbursing, and holding
            harmless the Company, its officers, directors, employees, and agents
            from any claims, costs, debts, and liabilities, including legal
            fees, due to:
          </p>
          <ol>
            <li>Your use of and access to the Application/Website/Services;</li>
            <li>
              Your violation of any of these Terms or any other Company policy;
            </li>
            <li>
              Your violation of any third-party rights, including copyright,
              property, or privacy rights;
            </li>
            <li>
              Any claim that your use of the Application/Website/Services has
              caused damage to a third party.
            </li>
          </ol>
          <p>
            The obligation to defend and reimburse the injured party continues
            even after the agreement.
          </p>
          <h6 id="limitation-of-liability">Limitation of Liability</h6>
          <p>
            The Company, its officers, directors, employees, partners, or agents
            will not accept liability for any special, incidental, indirect,
            consequential, or punitive damages related to your use of the
            Application.
          </p>
          <h6 id="breach-and-termination">Breach and Termination</h6>
          <p>
            If you breach these terms, the Company’s reputation could be
            damaged, and they might not be able to recover losses. In such an
            event, the Company can seek a court order to prevent further breach.
            The Company may also pursue additional remedies and suspend or
            terminate your account immediately if you violate any portion of
            this clause. The Company may reveal your account information if
            necessary. Violating these terms may also subject you to local laws.
          </p>
          <h6 id="legal-jurisdiction">Legal Jurisdiction</h6>
          <p>
            The legal matters of this contract are governed by the laws of
            Bangladesh, regardless of conflicts of law. Disputes will be
            resolved in the jurisdiction of the relevant courts in Dhaka, and
            you agree to accept their authority.
          </p>
          <h6 id="modifications-to-services">Modifications to Services</h6>
          <p>
            The Company may adjust, cease, or eliminate any part of the
            application or service at any time without prior notice.
            Additionally, the Company may alter these Terms from time to time
            without notice. The Company does not guarantee the continued
            operation or availability of any feature of the Application. The
            Company is not liable for modifications, interruptions, or
            terminations of the Application/Services. Prices may change at any
            time.
          </p>
          <h6 id="disclaimer">Disclaimer</h6>
          <span style={{ textTransform: "uppercase" }}>
            The website, application, and services are provided “as is” with all
            faults and without any warranty of any kind. The Company disclaims
            all warranties and conditions, including implied warranties and
            conditions of merchantability, fitness for a particular purpose,
            title, accuracy, timeliness, performance, completeness, suitability,
            and non-infringement. The Company is not liable for any damages
            arising from the use of this site, application, or services. Using
            any information or materials on this
            website/application/services/products is at your own risk, and it is
            your responsibility to ensure that the services meet your specific
            requirements.
          </span>
          <h6 id="general-provisions">General Provisions</h6>
          <ol>
            <li>
              Legal notifications will be sent using your email address and/or
              the application. The Company should be notified at{" "}
              <a href="mailto:contact@rootsedulive.com">
                contact@rootsedulive.com
              </a>
              .
            </li>
            <li>
              These Terms, the Privacy Policy, and any other relevant rules
              apply to your use of the Application, Website, and Services.
            </li>
            <li>
              The Terms cannot be transferred or assigned to any other party.
              The Company may freely transfer its rights under the Terms without
              needing your permission.
            </li>
            <li>
              If any provision of the Terms is deemed unenforceable, the
              remaining terms will continue in full force and effect.
            </li>
            <li>
              The Company will not waive any of its rights under the Terms by
              failing to enforce or exercise them.
            </li>
            <li>
              By being part of the service, you are not a Company employee,
              agency, partnership, or franchise.
            </li>
            <li>
              These terms apply to your use of the website/application and
              services, and you have agreed to them after sufficient time to
              evaluate them.
            </li>
          </ol>
          <h6 id="feedback">Feedback</h6>
          <p>
            Your feedback regarding the Application is considered
            non-confidential. The Company can use this information as it
            chooses. By providing feedback, you represent and warrant that:
          </p>
          <ol>
            <li>
              Your feedback does not contain confidential or proprietary
              information from you or third parties;
            </li>
            <li>
              The Company is not obligated to maintain confidentiality regarding
              the feedback;
            </li>
            <li>
              The Application may be similar to feedback already being
              considered or under development.
            </li>
          </ol>
          <p>
            The Company is not liable for content published by other users,
            including inappropriate or harmful content. Exercise caution when
            using the Application.
          </p>
          <h6 id="customer-service">Customer Service</h6>
          <p>
            We aim to make your experience enjoyable. If you encounter any
            issues, please contact us at{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>
            .
          </p>
        </CardContent>
      </Card>
      <Card sx={{ paddingLeft: 2, paddingTop: 4 }}>
        <CardContent sx={{ maxWidth: 1200 }}>
          <h5 id="শর্তাবলী-ও-নিয়মাবলী">শর্তাবলী ও নিয়মাবলী</h5>
          <p>
            এই শর্তাবলী ও নিয়মাবলী (“শর্তাবলী”) নিয়ন্ত্রণ করে (ক) আমাদের
            ওয়েবসাইট{" "}
            <a href="http://www.rootsedulive.com">www.rootsedulive.com</a>{" "}
            (“ওয়েবসাইট”), আমাদের অ্যাপ্লিকেশন (“অ্যাপ্লিকেশন”), বা
            অ্যাপ্লিকেশন, ওয়েবসাইট, বা পণ্যের সাথে সম্পর্কিত যেকোনো পণ্য বা
            পরিষেবা (“পরিষেবা”) ব্যবহারের নিয়মাবলী নির্ধারণ করে, অথবা (খ)
            যেকোনো নিবন্ধন বা পণ্য ব্যবহারের মোড, যেমন এসডি কার্ড, ট্যাবলেট, বা
            অন্যান্য স্টোরেজ/ট্রান্সমিটিং ডিভাইসের মাধ্যমে। এই শর্তাবলী ROOTs
            Edu (“কোম্পানি/আমরা/আমাদের”) এবং এর ব্যবহারকারীদের
            (“ব্যবহারকারী/আপনি/আপনার”) মধ্যে প্রযোজ্য।
          </p>
          <h6 id="শর্তাবলী-গ্রহণ">শর্তাবলী গ্রহণ</h6>
          <p>
            অনুগ্রহ করে নিবন্ধন করার পূর্বে, অ্যাপ্লিকেশন, ওয়েবসাইট, বা পরিষেবা
            ব্যবহারের পূর্বে এই শর্তাবলী এবং আমাদের গোপনীয়তা নীতি মনোযোগ সহকারে
            পড়ুন। অ্যাপ্লিকেশন, ওয়েবসাইট, বা পরিষেবার শর্তাবলী এবং অন্য নীতির
            মধ্যে কোনো বৈপরীত্য থাকলে, শর্তাবলী প্রযোজ্য হবে। অ্যাপ্লিকেশন,
            ওয়েবসাইট, বা পরিষেবায় প্রবেশ করে আপনি এই শর্তাবলী আইনীভাবে বাধ্য
            হওয়ার জন্য সম্মতি প্রদান করছেন।
          </p>
          <h6 id="নিবন্ধন-ও-সাবস্ক্রিপশন">নিবন্ধন ও সাবস্ক্রিপশন</h6>
          <p>
            আমাদের অ্যাপ্লিকেশন, সহ আমাদের সকল পরিষেবা এবং পণ্যসমূহ শুধুমাত্র
            নিবন্ধন/সাবস্ক্রিপশন এর মাধ্যমে প্রবেশযোগ্য।
          </p>
          <h6 id="স্বত্বাধিকারী-তথ্য">স্বত্বাধিকারী তথ্য</h6>
          <p>
            অ্যাপ্লিকেশন, ওয়েবসাইট, পরিষেবা, এবং পণ্যসমূহে অন্তর্ভুক্ত
            সফটওয়্যার, টেক্সট, ছবি, গ্রাফিক্স, ভিডিও, স্ক্রিপ্ট, এবং অডিও, সহ
            অন্যান্য তথ্য, বিষয়বস্তু, উপকরণ, ট্রেডমার্ক, পরিষেবা চিহ্ন, ট্রেড
            নাম, এবং ট্রেড সিক্রেটসমূহ কোম্পানির স্বত্বাধিকারী সম্পত্তি
            (“স্বত্বাধিকারী তথ্য”)। স্বত্বাধিকারী তথ্য পুনরুত্পাদন, পুনঃব্যবহার,
            পরিবর্তন, এবং বিতরণের অনুমতি কোম্পানির পূর্ব অনুমোদন ছাড়া প্রাপ্ত
            হতে পারে না। অ্যাপ্লিকেশন, ওয়েবসাইট, বা পরিষেবার ব্যবহারকারীরা
            কোম্পানির মেধাসত্ত্বের মালিকানা দাবি করতে পারবেন না। আপনি বিষয়বস্তু
            নিয়ন্ত্রণ করতে পারেন, কিন্তু কোম্পানি সর্বদা মাধ্যমের উপর তথ্য,
            বিষয়বস্তু, এবং সমস্ত মেধাসত্ত্বের মালিকানা রাখবে।
          </p>
          <p>
            ওয়েবসাইট ব্যবহার করার সময় আপনি অন্যদের মালিকানাধীন উপকরণের
            সম্মুখীন হতে পারেন। বিষয়বস্তু প্রদানের পূর্বে বিষয়ের সম্মতি
            প্রাপ্ত করা হয়েছে এবং সমস্ত অধিকার তৃতীয় পক্ষের মালিকানায় রয়েছে।
            তাছাড়া, আপনি স্বীকার করেন যে সমস্ত মেধাসত্ত্ব, সহ ট্রেডমার্ক,
            কপিরাইট, লোগো, এবং পরিষেবা চিহ্নসমূহ যথাক্রমে তৃতীয় পক্ষের
            মালিকানায় রয়েছে এবং তৃতীয় পক্ষের অনুমতি ছাড়া আপনি তা ব্যবহার
            করতে পারবেন না।
          </p>
          <h6 id="অনুমোদিত-ব্যবহার">অনুমোদিত ব্যবহার</h6>
          <p>
            ব্যক্তিগত বা অ-বাণিজ্যিক উদ্দেশ্যে আমাদের পণ্য, পরিষেবা, ওয়েবসাইট,
            এবং অ্যাপ্লিকেশন ব্যবহার করা নিষিদ্ধ। ব্যক্তিগত এবং অ-বাণিজ্যিক
            উদ্দেশ্যে অ্যাপ্লিকেশন, ওয়েবসাইট, এবং আমাদের পরিষেবা ব্যবহারের জন্য
            নিম্নলিখিত নিয়মগুলি প্রযোজ্য:
          </p>
          <ol>
            <li>
              আপনি অ্যাপ্লিকেশন, ওয়েবসাইট, বা পরিষেবা/পণ্য থেকে প্রাপ্ত তথ্য বা
              সফটওয়্যার কপি, পরিবর্তন, অনুবাদ, প্রদর্শন, বিতরণ, বা প্রদর্শন
              করতে পারবেন না বা অ্যাপ্লিকেশন, ওয়েবসাইট, বা পরিষেবা/পণ্যের
              বিষয়বস্তুর থেকে কোনো কপিরাইট, ট্রেডমার্ক নিবন্ধন, বা অন্য কোনো
              স্বত্বাধিকারী বিজ্ঞপ্তি অপসারণ করতে পারবেন না।
            </li>
            <li>
              বাণিজ্যিক উদ্দেশ্যে, সহ বিক্রি, বিজ্ঞাপন, বা অ্যাপ্লিকেশন বা
              আমাদের যেকোনো পণ্য বা পরিষেবার প্রচার, দান চাওয়া, বা বাণিজ্যিক
              উদ্দেশ্যে পাবলিক ফোরাম ব্যবহার করা নিষিদ্ধ।
            </li>
          </ol>
          <h6 id="নিষিদ্ধ-আচরণ">নিষিদ্ধ আচরণ</h6>
          <p>
            আমাদের পরিষেবা ব্যবহারের সময় নিম্নলিখিত কাজগুলি কঠোরভাবে নিষিদ্ধ:
          </p>
          <ul>
            <li>
              প্রতারণামূলক, অবৈধ, বিপজ্জনক, আপত্তিকর, মানহানিকর, অশ্লীল, শিশু
              পর্নোগ্রাফি, উচ্চস্বরে, লাস্যময়, অবৈধ, হানিকর, ঘৃণ্য, জাতিগত, বা
              অন্যথায় আপত্তিকর বিষয়বস্তু সরবরাহ করা।
            </li>
            <li>
              অন্য ব্যক্তিকে হুমকি দেওয়া, অনুসরণ করা, এবং/অথবা হয়রানি করা, সহ
              অপরাধে উস্কানিমূলক আচরণ করা।
            </li>
            <li>
              অবৈধ কাজ উস্কানিতে তথ্য প্রেরণ করা, যা নাগরিক দায়বদ্ধতা সৃষ্টি
              করে বা যে কোনো প্রযোজ্য আইন, বিধি, বা নির্দেশিকা লঙ্ঘন করে।
            </li>
            <li>
              অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবার অন্য ব্যবহারকারীর ব্যবহার বা
              উপভোগে বাধা দেওয়া।
            </li>
            <li>
              কপিরাইট আইনের অধীনে সুরক্ষিত মেধাসত্ত্বের কোনো পুনরুত্পাদন,
              সংরক্ষণ, বা অন্যথায় পুনরুৎপাদন করা বা অন্যদের এমন কিছু প্রদান করা
              যা কোনো পক্ষের মেধাসত্ত্ব বা অন্য স্বত্বাধিকারী অধিকার লঙ্ঘন করে।
            </li>
            <li>
              কোনো আইন বা চুক্তি বা বিশ্বাসযোগ্য দায়িত্বের অধীনে অন্য কাউকে
              কোনো তথ্য বা কিছু প্রদান করা যা আপনি সরবরাহ করতে অধিকারপ্রাপ্ত না।
            </li>
            <li>
              মিথ্যাভাবে কোনো ব্যক্তি বা সত্তার পরিচয় ধারণ করা, বা কোনো ব্যক্তি
              বা সত্তার সাথে আপনার সম্পর্ক ভুলভাবে উপস্থাপন করা।
            </li>
            <li>
              ভাইরাস, ট্রোজান, ওয়ার্ম, স্পাইওয়্যার, টাইম বোম্ব, ক্যান্সেলবট,
              বা অন্য কম্পিউটার প্রোগ্রামিং রুটিন, কোড, ফাইল, বা প্রোগ্রামসমূহের
              মাধ্যমে অ্যাপ্লিকেশন/পরিষেবায় ক্ষতি, সীমিত ফাংশনালিটি সৃষ্টি করা।
            </li>
            <li>
              অ্যাপ্লিকেশন বা ওয়েবসাইটের সার্ভার বা নেটওয়ার্কে ক্ষতি, অতিরিক্ত
              লোড, বা ক্র্যাশ সৃষ্টির উদ্দেশ্যে ব্যবহার করা।
            </li>
            <li>
              অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা/পণ্য ব্যবহারের সাথে সম্পর্কিত কোনো
              আইন বা বিধি লঙ্ঘন করা, বা শর্তাবলীর দ্বারা নিষিদ্ধ কিছু করা।
            </li>
            <li>
              অ্যাকাউন্ট, সার্ভার, বা নেটওয়ার্কের নিরাপত্তা ক্ষতি করা, অথবা
              অনুমতি ছাড়া অ্যাকাউন্ট, সার্ভার, বা নেটওয়ার্কে প্রবেশ করা।
            </li>
            <li>
              অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা/পণ্য বা আমাদের অন্য কোনো সাইট বা
              সিস্টেমে প্রবেশ বাধাগ্রস্ত করা বা পরিষেবা অস্বীকার করার আক্রমণ
              (ডিনায়াল অফ সার্ভিস) করা।
            </li>
            <li>
              অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা/পণ্য দ্বারা প্রকাশিত নয় এমন
              মাধ্যমে কোনো তথ্য সংগ্রহ, কপি, বা নিরীক্ষণ করা, সহ ম্যানুয়াল
              প্রসেস, ডিভাইস, প্রোগ্রাম, অ্যালগরিদম, পদ্ধতি বা সমতুল্য ডিভাইস,
              সরঞ্জাম, বা ম্যানুয়াল ক্রিয়াকলাপ।
            </li>
            <li>পরিষেবার যেকোনো উপাদান পরিবর্তন বা সংশোধন করা।</li>
            <li>
              শর্তাবলী লঙ্ঘন করে বা আইন, বিধি, বা প্রাসঙ্গিক দেশের সাধারণভাবে
              গৃহীত প্রথা বা মান অনুযায়ী পরিষেবা ব্যবহার করা।
            </li>
            <li>
              অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা/পণ্য ব্যবহারের শর্তাবলীর কোনো
              শর্তাবলী অতিক্রম করা।
            </li>
          </ul>
          <h6 id="লাইসেন্স-প্রদান">লাইসেন্স প্রদান</h6>
          <p>
            আপনি আমাদের একটি বিশ্বব্যাপী, অ-এক্সক্লুসিভ, রয়্যালটি-মুক্ত
            লাইসেন্স প্রদান করেন (সাবলাইসেন্স করার অধিকার সহ) আপনার বিষয়বস্তু
            ব্যবহারের, কপি করার, পুনরুত্পাদন করার, প্রক্রিয়া করার, অভিযোজন
            করার, পরিবর্তন করার, প্রকাশ করার, প্রেরণ করার, প্রদর্শন করার, এবং
            বিতরণ করার জন্য, যা আপনি পরিষেবার মাধ্যমে প্রদান করেন, আপনাকে
            সংযুক্ত করার অধিকার সহ, যদি নিষিদ্ধ না হয়। আপনি অন্যদের আপনার
            বিষয়বস্তু একইভাবে ব্যবহারের অনুমতি দেন যেভাবে পরিষেবায় পাওয়া
            যেকোনো বিষয়বস্তু ব্যবহার করা যেতে পারে।
          </p>
          <h6 id="সঠিকতা-এবং-আপডেট">সঠিকতা এবং আপডেট</h6>
          <p>
            অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা/পণ্য এবং এর উপাদানসমূহে সরবরাহিত
            তথ্যগুলি বর্তমান, সঠিক, এবং স্পষ্ট রাখার জন্য যথাসম্ভব চেষ্টা করা
            হয়েছে। যদিও অনিচ্ছাকৃত ভুল হতে পারে, কোম্পানি স্পষ্টভাবে
            অ্যাপ্লিকেশনে থাকা তথ্যের সত্যতার জন্য কোনো দায়িত্ব অস্বীকার করে।
            ব্যবহারকারীদের ইনপুট গ্রহণযোগ্য যে অ্যাপ্লিকেশন এবং এর বিষয়বস্তু
            ত্রুটিমুক্ত এবং ব্যবহারকারী-বান্ধব করতে সাহায্য করে। কোম্পানি যে
            কোনো সময়ে, আগাম নোটিশ ছাড়াই, বিষয়বস্তুতে কোনো পরিবর্তন বা সংশোধন
            করার অধিকার সংরক্ষণ করে। কোম্পানি বা কোনো তৃতীয় পক্ষ
            অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা/পণ্যগুলিতে পাওয়া বা প্রদত্ত তথ্য এবং
            উপকরণগুলির নির্ভুলতা, সময়মততা, বা উপযুক্ততার বিষয়ে কোনো গ্যারান্টি
            দেয় না। আপনি বোঝেন যে আমরা যে তথ্য এবং উপকরণ সরবরাহ করছি তাতে
            ত্রুটি থাকতে পারে এবং আমরা তাদের নির্ভুলতা সম্পর্কে কোনো প্রতিশ্রুতি
            দিই না।
          </p>
          <h6 id="শিক্ষামূলক-বিষয়বস্তু">শিক্ষামূলক বিষয়বস্তু</h6>
          <p>
            আমাদের ওয়েবসাইট সমন্বিত শিক্ষামূলক বিষয়বস্তু এবং প্রাসঙ্গিক উৎসের
            জন্য একটি রিসোর্স কেন্দ্র হিসেবে কাজ করে। আমরা এই তথ্যের সঠিকতা,
            পূর্ণতা, বা ব্যবহারের দায়িত্ব গ্রহণ করি না, যা “যেমন আছে” ভিত্তিতে
            প্রদান করা হয়। সাইটের তথ্য আপডেট করা বা সঠিক হওয়ার নিশ্চয়তা আমরা
            দিচ্ছি না। আমরা যেকোনো তথ্যের অসামঞ্জস্যতার জন্য, যা তৃতীয় পক্ষের
            উৎস থেকে আসে বা যা অপ্রাপ্য, আমাদের দায়বদ্ধতা থাকবে না। সাইটটি
            সময়ে সময়ে অন্যান্য ওয়েবসাইটের হাইপারলিংক সরবরাহ করতে পারে। এই
            লিংকগুলি আপনার তথ্যের জন্য প্রদান করা হয় যাতে আপনি আরও বিশদ জানতে
            পারেন। এই লিংকগুলির মাধ্যমে ওয়েবসাইটকে সমর্থন করি না। আমরা লিংককৃত
            ওয়েবসাইটগুলির দায়িত্ব গ্রহণ করি না। কোম্পানির স্পষ্ট লিখিত অনুমতি
            ছাড়া সাইটে লিংক তৈরি করা যাবে না।
          </p>
          <h6 id="পাঠ্যক্রম-ভিত্তিক-উপকরণ">পাঠ্যক্রম ভিত্তিক উপকরণ</h6>
          <p>
            উপকরণ এবং পণ্যসমূহ বিভিন্ন শ্রেণীর শিক্ষার্থীদের জন্য নির্ধারিত
            পাঠ্যক্রমের ধারণার উপর ভিত্তি করে। অ্যাপ্লিকেশন/পরিষেবা/পণ্যগুলি
            প্রচলিত শিক্ষার বিকল্প হিসেবে দেখা উচিত নয়; বরং, এটি জটিল ধারণাগুলি
            সহজে বোঝার উপায় হিসেবে এটি সম্পূরক হিসাবে প্রদান করা হয়েছে।
            বিষয়বস্তু সংক্রান্ত সবকিছু একই থাকবে, কেবল সংজ্ঞা এবং সমীকরণগুলি
            বাদে। কোম্পানি বোঝে যে পাঠ্যক্রমের কাঠামোবদ্ধ শিক্ষাপদ্ধতি উপস্থাপন
            করার অন্যান্য উপায় রয়েছে, এবং অ্যাপ্লিকেশন/পরিষেবা/পণ্যে
            অন্তর্ভুক্ত পদ্ধতিগুলি কোম্পানি যে কোনো একটি অনুমোদন করে তার অর্থ
            নয়। আমাদের পরিষেবা/ওয়েবসাইট/পণ্যগুলির ব্যবহার কোনো
            শিক্ষাপ্রতিষ্ঠানে প্রবেশের নিশ্চয়তা দেয় না বা কোনো পরীক্ষায় সফলতা
            বা নির্ধারিত শতাংশ অর্জনের নিশ্চয়তা দেয় না।
          </p>
          <h6 id="মতামত-এবং-গ্যারান্টি">মতামত এবং গ্যারান্টি</h6>
          <p>
            অ্যাপ্লিকেশন/পরিষেবা/ওয়েবসাইট/পণ্যের বিষয়বস্তু (বিশেষত প্রশাসনিক
            কাজগুলিতে সাহায্যকারী পরিষেবাগুলি সম্পর্কিত) মাঝে মাঝে মতামত হতে
            পারে। এমন মতামতগুলির দায় কোম্পানির উপর থাকবে না। কোম্পানি তথ্য এবং
            ডেটার গুণমান, বিষয়বস্তু, বা পূর্ণতার উপর কোনো গ্যারান্টি দেয় না।
          </p>
          <h6 id="ব্যবহারকারী-সৃষ্ট-সামগ্রী">ব্যবহারকারী-সৃষ্ট সামগ্রী</h6>
          <p>
            পরিষেবাগুলিতে ইন্টারেক্টিভ বিষয়বস্তু অন্তর্ভুক্ত রয়েছে, এবং আমরা
            ব্যবহারকারীদের সামগ্রী প্রকাশের জন্য জমা দেওয়ার উৎসাহ প্রদান করি।
            এই সামগ্রী প্রকাশের পূর্বে সম্পাদনার অধীনে থাকবে। কোম্পানি এই
            পদ্ধতিতে যোগাযোগ করা কোনো বিষয়বস্তুর জন্য কোনো দায় বা বাধ্যবাধকতা
            গ্রহণ করে না। কোম্পানি যে কোনো বিষয়বস্তু সরিয়ে নেওয়া, পর্যালোচনা
            করা, সম্পাদনা করা, বা মুছে ফেলার অধিকার সংরক্ষণ করে। আমরা
            ব্যবহারকারীর দ্বারা উত্পন্ন কোনো বিষয়বস্তুর জন্য দায়বদ্ধ থাকব না,
            যদিও আমরা ব্যবহারকারী-দাখিলিত বিষয়বস্তু অনুমোদন করেছি। এটি
            ব্যবহারকারীর দায়িত্ব যে তাদের বিষয়বস্তু কোনো কারোর কপিরাইট লঙ্ঘন
            না করে।
          </p>
          <h6 id="যোগাযোগ-এবং-যোগাযোগের-মাধ্যম">
            যোগাযোগ এবং যোগাযোগের মাধ্যম
          </h6>
          <p>
            কোম্পানি (এবং এর সহযোগী/অধীনস্থ সংস্থা) এসএমএস, ইমেল, এবং ফোন কলের
            মাধ্যমে ব্যবহারকারীদের সাথে যোগাযোগ করতে পারে তাদের পরিষেবা এবং পণ্য
            সম্পর্কে জানাতে এবং তাদের অফারগুলি প্রদর্শনের অনুমতি চাইতে।
            ব্যবহারকারী কোম্পানি (এবং এর সহযোগী/অধীনস্থ সংস্থা) কে টেলিফোন,
            এসএমএস, এবং ইমেলের মাধ্যমে যোগাযোগ করার অনুমতি প্রদান করে।
            ব্যবহারকারী সম্মত হয় যে তাদের মোবাইল নম্বর ডু নট কল (ডিএনসি)
            ডেটাবেসে নিবন্ধিত থাকলে যে কোনো দায়, আর্থিক জরিমানা, ক্ষতি, এবং
            ব্যয় থেকে কোম্পানি নির্দোষ থাকবে। আপনি একবার নিবন্ধন করার পর ফোন,
            এসএমএস, এবং ইমেলের মাধ্যমে বিপণন এবং শিক্ষামূলক তথ্যের জন্য বিভিন্ন
            মাধ্যমে যোগাযোগ করা হবে।
          </p>
          <h6 id="পণ্যের-প্রদর্শনী">পণ্যের প্রদর্শনী</h6>
          <p>
            কোম্পানি ব্যবহারকারীর সম্মতিতে নির্দিষ্ট স্থানে তাদের পণ্যের
            প্রদর্শনীতে সহায়তা করতে পারে। ব্যবহারকারী বোঝে যে তারা পণ্যের
            গুণমান বা অন্যান্য বৈশিষ্ট্যগুলি সম্পর্কে বিভ্রান্ত হননি এবং তারা
            শুধুমাত্র তাদের নিজস্ব তদন্ত, পরিদর্শন, এবং মূল্যায়নের উপর নির্ভর
            করেছেন।
          </p>
          <h6 id="পরিষেবা-অপ্টিমাইজেশনের-জন্য-যোগাযোগ">
            পরিষেবা অপ্টিমাইজেশনের জন্য যোগাযোগ
          </h6>
          <p>
            কোম্পানি আপনার নিবন্ধিত মোবাইল নম্বর, ইমেল, বা আপনি প্রদত্ত অন্য
            কোনো যোগাযোগের মাধ্যমে আপনাকে পৌঁছাতে পারে যাতে আমরা আমাদের
            পরিষেবাগুলি আরও ভালভাবে সরবরাহ করতে পারি। ব্যবহারকারী সম্মত হয় যে
            পরিষেবাগুলির মাধ্যমে কোম্পানি এবং শিক্ষার্থীর কাছ থেকে যোগাযোগ এবং
            বার্তা গ্রহণ করবে। পরিষেবার ব্যবহার উন্নত এবং অপ্টিমাইজ করার জন্য
            কোম্পানি ব্যবহারকারী/শিক্ষার্থীর ডাউনলোড এবং
            অ্যাপ্লিকেশন/পরিষেবা/পণ্যের ব্যবহার পর্যবেক্ষণ করতে পারে এবং
            ব্যবহারকারী/শিক্ষার্থীর সাথে যোগাযোগ করতে পারে। শিক্ষার্থীরা তাদের
            অ্যাসাইনমেন্ট সম্পর্কে যে কোনো প্রশ্ন পরিষেবার মাধ্যমে পরিষ্কার করার
            জন্য সম্মত হতে হবে, এবং অ্যাপ্লিকেশনটি শিক্ষার্থীদের পাঠ্যক্রম
            সম্পর্কে যে কোনো প্রশ্নের উত্তর দিতে হবে।
          </p>
          <h6 id="কর্মচারী-আচরণ-এবং-গুণগত-মান-নিয়ন্ত্রণ">
            কর্মচারী আচরণ এবং গুণগত মান নিয়ন্ত্রণ
          </h6>
          <p>
            কোম্পানি যদিও তার বিক্রয় এবং পরিষেবা কর্মচারীদের গুণগত মান
            নিয়ন্ত্রণের জন্য প্রশিক্ষণ দেওয়ার চেষ্টা করেছে, তবুও কর্মচারীদের
            সেই ক্ষমতা সম্পর্কে কোনো গ্যারান্টি বা আশ্বাস প্রদান করে না এবং তার
            কোনো কর্মচারীর দ্বারা কোনো অনৈতিক কাজের জন্য দায়বদ্ধ নয়। কোম্পানি
            এই বিষয়ে ব্যবহারকারীর মতামত গ্রহণ করে এবং তার মতামত অনুযায়ী
            ব্যবস্থা নেওয়ার অধিকার সংরক্ষণ করে।
          </p>
          <h6 id="পরিষেবার-শর্তাবলী-এবং-ন্যায্য-ব্যবহার-নীতি">
            পরিষেবার শর্তাবলী এবং ন্যায্য ব্যবহার নীতি
          </h6>
          <p>
            পরিষেবার কিছু উপাদান, যেমন পরামর্শ, কেস ম্যানেজমেন্ট, ইত্যাদি তাদের
            নিজস্ব শর্তাবলী, শর্তাবলী এবং ন্যায্য ব্যবহার নীতির অধীনে হতে পারে।
            কোম্পানির বিভিন্ন পরিষেবা দিকগুলির প্রয়োজনীয়তা নির্ধারণের ক্ষমতা
            রয়েছে তার নিয়মাবলীর উপর ভিত্তি করে। অ্যাপ্লিকেশনে সাবস্ক্রিপশন বা
            পরিষেবাগুলির জন্য সাইন আপ করা কোম্পানির প্রদত্ত সব কিছুর অধিকার
            নিশ্চিত করে না। এটি কোম্পানির উপর নির্ভর করে যে কাকে নির্দিষ্ট
            পরিষেবাগুলিতে অ্যাক্সেস দেওয়া হবে। আমাদের পরিষেবাগুলি আগাম নোটিশ
            ছাড়াই বাড়ানো, বাতিল করা, বন্ধ করা, বা পরিবর্তন করা যেতে পারে।
          </p>
          <h6 id="সামঞ্জস্যতা-এবং-আপগ্রেড">সামঞ্জস্যতা এবং আপগ্রেড</h6>
          <p>
            কোম্পানির পণ্য এবং/অথবা পরিষেবাগুলির সাথে নির্দিষ্ট বৈশিষ্ট্যযুক্ত
            ডিভাইস, ট্যাবলেট, যন্ত্র এবং হার্ডওয়্যার সামঞ্জস্যপূর্ণ, যার মধ্যে
            অ্যাপ্লিকেশন এবং বিষয়বস্তু অন্তর্ভুক্ত রয়েছে। কোম্পানি এমন যেকোনো
            যন্ত্রের জন্য কার্যকরী পণ্য এবং পরিষেবাগুলি সরবরাহ করতে বাধ্য নয় যা
            এটি গ্রহণ করে না বা যা তৃতীয় পক্ষ থেকে কেনা হয়েছে এবং যা কোম্পানির
            পণ্যের সাথে কাজ করে না। প্রয়োজন অনুযায়ী কোম্পানি ডিভাইস/ট্যাবলেটের
            ধরন আপগ্রেড করতে পারে।
          </p>
          <h6 id="দায়-বর্জন">দায় বর্জন</h6>
          <p>
            আমাদের পণ্য এবং পরিষেবার ব্যবহার থেকে উদ্ভূত কোনো ক্ষতি বা ক্ষতির
            জন্য কোম্পানি দায়বদ্ধ থাকবে না, যার মধ্যে তথ্য হারানো বা প্রসেসিং
            গতি প্রভাবিত হওয়া অন্তর্ভুক্ত।
          </p>
          <h6 id="শিপিং-এবং-রিটার্ন">শিপিং এবং রিটার্ন</h6>
          <ul>
            <li>
              ক্রয়ের সময় আপনাকে অবশ্যই সেই ঠিকানা নির্দিষ্ট করতে হবে যেখানে
              চালানটি সরবরাহ করা উচিত।
            </li>
            <li>
              সমস্ত পণ্য অর্ডার করার সময় প্রদত্ত ঠিকানায় সরবরাহ করা আবশ্যক,
              এবং ক্রয় সম্পন্ন হওয়ার পরে ডেলিভারি ঠিকানা পরিবর্তন করা নিষিদ্ধ।
            </li>
            <li>
              আপনার প্রত্যাশিত শিপিং তারিখের পূর্বে আপনার মেইলিং ঠিকানায় যে
              কোনো পরিবর্তনের আমাদের অবহিত করতে হবে।
            </li>
            <li>
              আপনার নাম বা ঠিকানায় কোনো ত্রুটি থাকলে, আপনার ক্রয় সরবরাহ করা
              নাও হতে পারে।
            </li>
            <li>
              ডেলিভারির সময় ক্ষতিগ্রস্ত পণ্যগুলির রিটার্নের সাথে সম্পর্কিত
              শিপিং চার্জগুলি কোম্পানি বহন করবে।
            </li>
            <li>
              উপরোক্ত কারণগুলি ছাড়া অন্য কোনো কারণে বাতিল করা পণ্য(গুলি) ফেরত
              দেওয়ার ব্যবস্থা করা এবং শিপিং চার্জগুলি বহন করা ব্যবহারকারীর
              দায়িত্ব।
            </li>
            <li>
              যে কোনো ডেলিভারি গ্রহণ করবেন না যা পরিবর্তিত, খোলা, বা ক্ষতিগ্রস্ত
              বলে মনে হচ্ছে।
            </li>
            <li>
              কোম্পানির পণ্যগুলি তাদের মূল অবস্থায় ফেরত দিতে হবে। যেকোনোভাবে
              পরা বা ক্ষতিগ্রস্ত আইটেমগুলির জন্য রিটার্ন গ্রহণ করা হবে না।
            </li>
            <li>
              পণ্য ফেরতের সমস্ত অনুরোধ ডেলিভারির ১৫ দিনের মধ্যে করা আবশ্যক।
              ডেলিভারির ১৫ দিনের পর রিফান্ড গ্রহণ করা হবে না।
            </li>
          </ul>
          <h6 id="অ্যাকাউন্ট-নিবন্ধন-এবং-রক্ষণাবেক্ষণ">
            অ্যাকাউন্ট নিবন্ধন এবং রক্ষণাবেক্ষণ
          </h6>
          <p>
            অ্যাপ্লিকেশন এবং পরিষেবাগুলি ব্যবহার করতে, আপনাকে একটি অ্যাকাউন্ট
            নিবন্ধন এবং রক্ষণাবেক্ষণ করতে হবে, যার মধ্যে আপনার নাম, মোবাইল
            নম্বর, ইমেল ঠিকানা, বাসার ঠিকানা, শিক্ষার্থীর শ্রেণি এবং স্কুল, এবং
            অন্যান্য প্রয়োজনীয় তথ্য সরবরাহ করতে হবে। আপনার অ্যাকাউন্ট তথ্য
            সঠিক, পূর্ণাঙ্গ, এবং আপ-টু-ডেট রাখা শুধুমাত্র আপনার দায়িত্ব। আপনার
            অ্যাকাউন্ট তথ্য এবং আপনার অ্যাকাউন্টে সমস্ত কার্যকলাপ রক্ষা করাও
            আপনার দায়িত্ব। অ্যাকাউন্ট অ্যাক্সেস সমস্যাগুলি এড়াতে, আপনাকে
            প্রতিটি সেশনের শেষে লগ আউট করতে হবে এবং যে কোনো অননুমোদিত
            অ্যাক্সেসের ক্ষেত্রে কোম্পানিকে অবহিত করতে হবে। যদি নিরাপত্তা লঙ্ঘন
            বা অপব্যবহার সন্দেহ করা হয়, কোম্পানি আপনাকে আপনার পাসওয়ার্ড
            পরিবর্তন করতে বা কোনো দায়বদ্ধতা ছাড়াই আপনার অ্যাকাউন্ট স্থগিত করতে
            বলতে পারে। এই বিভাগের সাথে অসামঞ্জস্যের কারণে আপনি যে ক্ষতি বা
            ক্ষতির সম্মুখীন হন তার জন্য কোম্পানি দায়ী হবে না। আপনার অ্যাকাউন্ট
            ব্যবহারযোগ্যতা নির্ভর করে আপনার ইন্টারনেট পরিষেবা প্রদানকারী এবং
            নেটওয়ার্কের নির্ভরযোগ্যতার উপর। কোম্পানি নিশ্চিত করতে পারে না যে
            আপনি সর্বদা অ্যাপ্লিকেশন অ্যাক্সেস করতে পারবেন এবং আপনার অ্যাকাউন্টে
            লগ ইন করতে ব্যর্থতা থেকে উদ্ভূত ক্ষতির জন্য দায়ী হবে না।
          </p>
          <h6 id="যোগ্যতা">যোগ্যতা</h6>
          <p>
            ১৮ বছরের কম বয়সী ব্যক্তি বা যারা ঋণ নিষ্পত্তিতে রয়েছে তারা আমাদের
            পণ্য বা পরিষেবার জন্য নিবন্ধন করার যোগ্য নয়। নাবালকরা তাদের আইনি
            অভিভাবক বা পিতামাতার সম্মতিতে আমাদের পণ্য ও পরিষেবা ব্যবহার করতে
            পারে। যদি কোনো নাবালক অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবা ব্যবহার করে,
            তবে ধারণা করা হয় যে তারা এমন সম্মতি পেয়েছে। কোম্পানি আমাদের পণ্য
            বা পরিষেবার অপব্যবহার থেকে উদ্ভূত কোনো সমস্যার জন্য দায়ী নয়, যার
            মধ্যে নাবালকদের ব্যবহার অন্তর্ভুক্ত। পিতামাতা বা আইনি অভিভাবকরা
            শিক্ষার্থীদের অ্যাপ্লিকেশন ব্যবহার করার অনুমোদন দিতে হবে এবং সঠিক
            তথ্য ব্যবহার করতে হবে। যদি এটি পাওয়া যায় যে ১৮ বছরের কম বয়সী একজন
            ব্যবহারকারীর সঠিক অনুমোদন নেই, কোম্পানি তাদের সদস্যপদ বাতিল করার এবং
            পণ্য বা পরিষেবাগুলিতে প্রবেশাধিকার অস্বীকার করার অধিকার সংরক্ষণ করে।
            কোম্পানি ব্যবহারকারীদের বয়সের প্রয়োজনীয়তা পূরণের দায়িত্ব রাখে
            না; ব্যবহারকারীদের অবশ্যই ব্যক্তিগতভাবে নিশ্চিত করতে হবে যে তারা
            প্রয়োজনীয় যোগ্যতা পূরণ করে।
          </p>
          <h6 id="ব্যবহারকারীর-দায়িত্ব-এবং-প্রতিশ্রুতি">
            ব্যবহারকারীর দায়িত্ব এবং প্রতিশ্রুতি
          </h6>
          <p>
            আপনি কোম্পানি, এর কর্মকর্তা, পরিচালক, কর্মচারী, এবং এজেন্টদের যে
            কোনো দাবি, খরচ, ঋণ, এবং দায় থেকে, আইনি ফি সহ, রক্ষা করার, ক্ষতিপূরণ
            দেওয়ার, এবং নির্দোষ রাখার জন্য দায়ী:
          </p>
          <ol>
            <li>
              অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবাগুলির আপনার ব্যবহার এবং অ্যাক্সেস;
            </li>
            <li>এই শর্তাবলী বা কোম্পানির অন্য কোনো নীতির আপনার লঙ্ঘন;</li>
            <li>
              তৃতীয় পক্ষের কোনো অধিকার, যার মধ্যে কপিরাইট, সম্পত্তি, বা
              গোপনীয়তা অধিকার অন্তর্ভুক্ত;
            </li>
            <li>
              যে কোনো দাবি যে অ্যাপ্লিকেশন/ওয়েবসাইট/পরিষেবাগুলির আপনার ব্যবহারে
              তৃতীয় পক্ষের ক্ষতি হয়েছে।
            </li>
          </ol>
          <p>
            আঘাতপ্রাপ্ত পক্ষকে প্রতিরক্ষা এবং ক্ষতিপূরণ দেওয়ার বাধ্যবাধকতা
            চুক্তির পরেও অব্যাহত থাকবে।
          </p>
          <h6 id="দায়-সীমাবদ্ধতা">দায় সীমাবদ্ধতা</h6>
          <p>
            কোম্পানি, এর কর্মকর্তা, পরিচালক, কর্মচারী, অংশীদার, বা এজেন্ট আপনার
            অ্যাপ্লিকেশন ব্যবহারের সাথে সম্পর্কিত কোনো বিশেষ, আকস্মিক, পরোক্ষ,
            ফলস্বরূপ, বা দণ্ডমূলক ক্ষতির জন্য দায়ী থাকবে না।
          </p>
          <h6 id="লঙ্ঘন-এবং-সমাপ্তি">লঙ্ঘন এবং সমাপ্তি</h6>
          <p>
            যদি আপনি এই শর্তাবলী লঙ্ঘন করেন, কোম্পানির সুনাম ক্ষতিগ্রস্ত হতে
            পারে এবং তারা ক্ষতি পুনরুদ্ধার করতে সক্ষম নাও হতে পারে। এমন
            ক্ষেত্রে, কোম্পানি আরও লঙ্ঘন প্রতিরোধের জন্য আদালতের আদেশ চাইতে
            পারে। কোম্পানি অতিরিক্ত প্রতিকার গ্রহণ করতে পারে এবং যদি আপনি এই
            শর্তাবলীর কোনো অংশ লঙ্ঘন করেন তবে আপনার অ্যাকাউন্ট অবিলম্বে স্থগিত
            বা বাতিল করতে পারে। কোম্পানি প্রয়োজন হলে আপনার অ্যাকাউন্টের তথ্য
            প্রকাশ করতে পারে। এই শর্তাবলী লঙ্ঘন করলে স্থানীয় আইনগুলির অধীন হতে
            পারে।
          </p>
          <h6 id="আইনগত-এখতিয়ার">আইনগত এখতিয়ার</h6>
          <p>
            এই চুক্তির আইনি বিষয়গুলি বাংলাদেশের আইন দ্বারা নিয়ন্ত্রিত, আইন
            সংঘাত নির্বিশেষে। বিরোধগুলি ঢাকা অঞ্চলের প্রাসঙ্গিক আদালতগুলির
            অধিকারক্ষেত্রে সমাধান করা হবে এবং আপনি তাদের কর্তৃত্ব মেনে নিতে
            সম্মত হন।
          </p>
          <h6 id="পরিষেবাগুলিতে-পরিবর্তন">পরিষেবাগুলিতে পরিবর্তন</h6>
          <p>
            কোম্পানি যে কোনো সময় আগাম নোটিশ ছাড়াই অ্যাপ্লিকেশন বা পরিষেবার
            কোনো অংশ সামঞ্জস্য করতে, বন্ধ করতে, বা বাদ দিতে পারে। এছাড়াও,
            কোম্পানি সময়ে সময়ে এই শর্তাবলী পরিবর্তন করতে পারে আগাম নোটিশ
            ছাড়াই। কোম্পানি অ্যাপ্লিকেশনের কোনো বৈশিষ্ট্যের অব্যাহত অপারেশন বা
            উপলব্ধতা গ্যারান্টি দেয় না। কোম্পানি অ্যাপ্লিকেশন/পরিষেবাগুলির
            পরিবর্তন, ব্যাঘাত, বা সমাপ্তির জন্য দায়ী নয়। দাম যে কোনো সময়
            পরিবর্তিত হতে পারে।
          </p>
          <h6 id="দায়-বর্জন-1">দায় বর্জন</h6>
          <p>
            ওয়েবসাইট, অ্যাপ্লিকেশন, এবং পরিষেবাগুলি “যেমন আছে” ভিত্তিতে সমস্ত
            ত্রুটি সহ সরবরাহ করা হয় এবং কোনো ধরনের ওয়ারেন্টি ছাড়া। কোম্পানি
            সমস্ত ওয়ারেন্টি এবং শর্তাবলী বর্জন করে, যার মধ্যে অন্তর্নিহিত
            ওয়ারেন্টি এবং বিক্রয়যোগ্যতার শর্ত, নির্দিষ্ট উদ্দেশ্যে উপযুক্ততা,
            শিরোনাম, যথার্থতা, সময়সীমা, কর্মক্ষমতা, সম্পূর্ণতা, উপযুক্ততা, এবং
            অ-লঙ্ঘন অন্তর্ভুক্ত। এই সাইট, অ্যাপ্লিকেশন, বা পরিষেবাগুলির
            ব্যবহারের ফলে উদ্ভূত কোনো ক্ষতির জন্য কোম্পানি দায়ী নয়। এই
            ওয়েবসাইট/অ্যাপ্লিকেশন/পরিষেবা/পণ্যগুলির উপর প্রদত্ত কোনো তথ্য বা
            উপকরণের ব্যবহার আপনার নিজস্ব ঝুঁকিতে এবং পরিষেবাগুলি আপনার নির্দিষ্ট
            প্রয়োজনীয়তাগুলি পূরণ করে কিনা তা নিশ্চিত করা আপনার দায়িত্ব।
          </p>
          <h6 id="সাধারণ-বিধান">সাধারণ বিধান</h6>
          <ol>
            <li>
              আইনগত বিজ্ঞপ্তিগুলি আপনার ইমেল ঠিকানা এবং/অথবা অ্যাপ্লিকেশন
              ব্যবহার করে পাঠানো হবে। কোম্পানিকে contact@rootsedulive.com-এ
              অবহিত করতে হবে।
            </li>
            <li>
              এই শর্তাবলী, গোপনীয়তা নীতি, এবং কোনো প্রাসঙ্গিক নিয়ম আপনার
              অ্যাপ্লিকেশন, ওয়েবসাইট, এবং পরিষেবাগুলির ব্যবহারের ক্ষেত্রে
              প্রযোজ্য।
            </li>
            <li>
              শর্তাবলী অন্য কোনো পক্ষের কাছে হস্তান্তর বা বরাদ্দ করা যাবে না।
              কোম্পানি তার অধিকারগুলি শর্তাবলীর অধীনে মুক্তভাবে স্থানান্তর করতে
              পারে আপনার অনুমতির প্রয়োজন ছাড়াই।
            </li>
            <li>
              যদি শর্তাবলীর কোনো বিধান কার্যকর না হয়, বাকি শর্তাবলী সম্পূর্ণ
              শক্তিতে অব্যাহত থাকবে।
            </li>
            <li>
              শর্তাবলীর অধীনে কোম্পানি তার অধিকার প্রয়োগ বা অনুশীলন না করলে তা
              ত্যাগ করা হবে না।
            </li>
            <li>
              পরিষেবার অংশ হিসাবে আপনি কোম্পানির কর্মচারী, এজেন্সি, অংশীদারিত্ব,
              বা ফ্র্যাঞ্চাইজি নন।
            </li>
            <li>
              এই শর্তাবলী আপনার ওয়েবসাইট/অ্যাপ্লিকেশন এবং পরিষেবাগুলির
              ব্যবহারের ক্ষেত্রে প্রযোজ্য এবং আপনি সেগুলি মূল্যায়নের জন্য
              পর্যাপ্ত সময় পরে সম্মত হয়েছেন।
            </li>
          </ol>
          <h6 id="প্রতিক্রিয়া">প্রতিক্রিয়া</h6>
          <p>
            অ্যাপ্লিকেশন সম্পর্কে আপনার প্রতিক্রিয়া গোপনীয় বলে বিবেচিত হয় না।
            কোম্পানি এই তথ্য যেমন ইচ্ছা ব্যবহার করতে পারে। প্রতিক্রিয়া প্রদান
            করে, আপনি প্রতিনিধিত্ব এবং নিশ্চয়তা দেন যে:
          </p>
          <ol>
            <li>
              আপনার প্রতিক্রিয়াতে আপনার বা তৃতীয় পক্ষের গোপনীয় বা মালিকানা
              তথ্য নেই;
            </li>
            <li>
              কোম্পানি প্রতিক্রিয়া সম্পর্কে গোপনীয়তা বজায় রাখতে বাধ্য নয়;
            </li>
            <li>
              অ্যাপ্লিকেশনটি ইতিমধ্যে বিবেচিত বা উন্নয়নের অধীনে প্রতিক্রিয়ার
              সাথে সামঞ্জস্যপূর্ণ হতে পারে।
            </li>
          </ol>
          <p>
            অন্য ব্যবহারকারীদের দ্বারা প্রকাশিত বিষয়বস্তুর জন্য কোম্পানি দায়ী
            নয়, যার মধ্যে অযৌক্তিক বা ক্ষতিকারক বিষয়বস্তু অন্তর্ভুক্ত।
            অ্যাপ্লিকেশন ব্যবহার করার সময় সতর্কতা অবলম্বন করুন।
          </p>
          <h6 id="গ্রাহক-পরিষেবা">গ্রাহক পরিষেবা</h6>
          <p>
            আমরা আপনার অভিজ্ঞতাটি আনন্দদায়ক করার চেষ্টা করি। আপনি যদি কোনো
            সমস্যার সম্মুখীন হন, দয়া করে আমাদের সাথে যোগাযোগ করুন{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>
            ।
          </p>
        </CardContent>
      </Card>
    </>
  );
}
