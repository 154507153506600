import React, { useEffect, useState, useRef , useLayoutEffect} from "react"
import axios from "../../axios";
import "./style.css";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { Danger } from "../../redux/action/toaster";
import { useDispatch } from "react-redux";
import CardSkeleton from "../../components/Skeleton/CardSkeleton"
export default function Appliveclass() {

  const [height, setHeight] = useState(0)
  let myself = useRef(null);


  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(window.location.search)
  const [eventCode, setEventCode] = useState();
  var course_ID = params.get('course_ID')
  var subscription_ID = params.get('subscription_ID')
  var live_ClassId = params.get('live_ClassId')
  var token = params.get('token')


 useEffect(() => {
    const url = `/my-courses/live-class-details?courseId=${course_ID}&subscriptionId=${subscription_ID}&liveClassId=${live_ClassId}`
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "x-auth-token": token,
            'Content-Type': 'application/json',
          }
        });
        const { eventCode } = response.data;
        setEventCode(eventCode)
        setLoading(false);
      } catch (error) {
        dispatch(Danger(`${error.response.data}`))
        setLoading(false);
      }
    };
    fetchData();
  }, []); 

  
  useLayoutEffect(()=>{
    if (myself.current) {
        const height = myself.current.clientHeight
        setHeight(height);
    }
}, [myself.current, myself.current?myself.current.clientHeight:0])

  
  return (
    <>
      {
        loading ? <CardSkeleton /> :

          <div className="main-container">
            <div innerRef={myself} className="event-container">
              <div className="event-body">

                <iframe
                  src={`https://player.vimeo.com/video/${eventCode}`}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                >
                </iframe>

              </div>
            </div>
            <div style={{height: `calc(100vh - ${height}px)`}} className="chat-container"  >
              <iframe
                 style={{height: `calc(100vh - ${height}px)`}}
                src={`https://vimeo.com/live-chat/${eventCode}`}
                frameBorder="0">
              </iframe>
            </div>
          </div>
      }
    </>
  );
}
// URL =http://localhost:3001/app/live-class?course_ID=6175488e7b658043b6934fab&live_ClassId=6185640bf99edcf764fb1ebf&subscription_ID=617548777b658043b6934ede&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MThmOWQwZDJiMTc4YzUyMzgwZTNjNjIiLCJyb2xlIjoic3VwZXJBZG1pbiIsImlhdCI6MTYzODI1NjU4M30.Y0SvPdH7UCxErCd_SwTQKjAOFjjGAWXJDptK6ypTW20