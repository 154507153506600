import { CardContent, CardHeader, Typography } from "@mui/material";
import Card from "@mui/material/Card";

export default function PrivacyPolicy() {
  return (
    <div>
      <Card sx={{ paddingLeft: 2 }}>
        <CardContent sx={{ maxWidth: 1200 }}>
          <h5 id="privacy-policy">Privacy Policy</h5>
          <h6 id="a.-general">A. General</h6>
          <p>
            We, ROOTs Edu, incorporated under the Bangladeshi law (hereinafter
            referred to as “Company” or “ROOTs Edu”), with the Trade License
            Number: TRAD/DNCC/071684/2022, are committed to safeguarding your
            privacy in relation to the protection of your personal information.
            The Services are governed by this Policy and any other rules,
            policies, or guidelines published on the Platform as applicable to
            you.
          </p>
          <p>
            Please read this Policy carefully prior to accessing our Platform
            and using the Services. By accessing and using the Platform,
            providing your Personal Information (defined below), or by otherwise
            signaling your agreement when the option is presented to you, you
            consent to the collection, use, disclosure, sharing, and storing of
            information described in this Policy, Terms of Service, and any
            other rules, policies, or guidelines published on the Platform as
            applicable to you (collectively referred to as the “Platform
            Terms”). ROOTs Edu disclaims all liabilities arising therefrom. If
            you have inadvertently submitted any Personal Information to ROOTs
            Edu prior to reading this Policy or if you do not agree with the way
            your Personal Information is collected, stored, or used, then you
            may access, modify, and/or delete your Personal Information in
            accordance with this Policy.
          </p>
          <p>
            If any information you have provided or uploaded on the Platform
            violates the Platform Terms, ROOTs Edu may be required to delete
            such information upon informing you of the same and revoke your
            access to the Platform if required. If you have any questions about
            this Policy, please contact us at{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>
            .
          </p>
          <h6 id="b.-controllers">B. Controllers</h6>
          <p>
            ROOTs Edu is the controller of Personal Information that it collects
            and processes in connection with the use of the Platform and the
            provision of the Services on the Platform. The kind of Personal
            Information we collect in connection with such use is detailed
            below.
          </p>
          <h6 id="c.-personal-information">C. Personal Information</h6>
          <p>
            “Personal Information” shall mean the information which identifies a
            User i.e., first and last name, identification number, email
            address, age, gender, location, photograph, and/or phone number
            provided at the time of registration or any time thereafter on the
            Platform.
          </p>
          <p>
            We request you not to provide ROOTs Edu with any Personal
            Information unless specifically requested by us. In the event you
            share with ROOTs Edu any Personal Information without us having
            specifically requested the same, then we bear no liability in
            respect of such Personal Information provided by you.
          </p>
          <h6 id="d.-information-we-collect">D. Information We Collect</h6>
          <p>
            We only collect information about you if we have a reason to do so —
            for example, to provide our Services on the Platform, to communicate
            with you, or to make our Services better.
          </p>
          <p>We collect this information from the following sources:</p>
          <ol>
            <li>
              <p>
                <strong>Information We Collect from You:</strong>
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Basic Account Information:</strong> To access
                    certain features of the Platform, you will need to create an
                    account and register with us. We ask for basic information,
                    which may include your name, email address, state of
                    residence, and password, along with a username and
                    phone/mobile number.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Information You Choose to Provide:</strong> When you
                    complete your profile within the Platform, you may choose to
                    provide your profile photo, name of your current educational
                    institution, and current grade. We explicitly ask for your
                    device camera permission if and when you want to update your
                    profile picture. This is not mandatory. This information is
                    not visible to anyone on or off the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Information When You Communicate with Us:</strong>{" "}
                    When you write to us with a question or to ask for help, we
                    will keep that correspondence and the email address for
                    future reference. This may also include any phone/mobile
                    numbers if you have provided them as part of your
                    communication either in writing (emails included), over a
                    phone call, or otherwise. When you browse pages on our
                    Platform, we will track that for statistical purposes, which
                    may be to improve the Platform and the Services. You may
                    also provide us with your Personal Information or other
                    information when you respond to surveys, enter any form of
                    contests, tests, events, competitions, webinars, etc.,
                    hosted by ROOTs Edu, either on the Platform or otherwise, or
                    when you otherwise communicate with us via form, e-form,
                    email, phone, or otherwise. We store a copy of our
                    communications (including any call recordings or emails, if
                    any, as permitted by applicable law). When you use our paid
                    doubt-solving feature, we explicitly ask for your device
                    camera permission so that you can share any associated image
                    with us.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Information Related to Location:</strong> You may
                    also choose to provide location-related information,
                    including but not limited to access to GPS, which will
                    enable us, with your consent, to offer customized offerings
                    for specific services where location data is relevant and/or
                    applicable, such as informing you whether services on the
                    Platform that you have expressed interest in may be availed
                    of at or near your location.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Information We Collect Automatically:</strong>
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Device and Log Information:</strong> When you access
                    our Platform, we collect information that web browsers,
                    mobile devices, and servers typically make available,
                    including the browser type, IP address, unique device
                    identifiers, language preference, referring site, the date
                    and time of access, operating system, and mobile network
                    information. We collect log information when you use our
                    Platform — for example, when you create or make changes to
                    your account information on the Platform.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Usage Information:</strong> We collect information
                    about your usage of our Platform. We also collect
                    information about what happens when you use our Platform
                    (e.g., page views, support document searches, features
                    enabled for your account, interactions with other parts of
                    our Services) along with information about your Supported
                    Device (e.g., screen size, name of cellular network, and
                    mobile device manufacturer). We use this information to
                    provide our Platform to you, get insights on how people use
                    our Platform so that we can make our Platform better, and
                    understand and make predictions about User retention.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Location Information:</strong> We may determine the
                    approximate location of your Supported Device from your
                    Internet Protocol (IP) address. We may collect and use this
                    information to calculate how many people visit from certain
                    geographic regions or to improve our Platform Services.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Information from Cookies &amp; Other Technologies:
                    </strong>{" "}
                    We may collect information about you through the use of
                    cookies and other similar technologies. A cookie is a string
                    of information that a website stores on a visitor’s
                    computer, and that the visitor’s browser provides to the
                    website each time the visitor returns.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Information We Collect from Other Sources:</strong>
              </p>
              <ul>
                <li>
                  We might receive and collect information about you from other
                  sources in the course of their services to us or from publicly
                  available sources, as permitted by law, which we may combine
                  with other information we receive from or about you. For
                  example, we may receive information about you from a social
                  media site or a Google service if you connect to the Services
                  through that site or if you use the Google sign-in.
                </li>
              </ul>
            </li>
          </ol>
          <h6 id="e.-basis-of-collection-and-processing-of-your-personal-information">
            E. Basis of Collection and Processing of Your Personal Information
          </h6>
          <p>
            <strong>Basis for Collection:</strong>
          </p>
          <p>
            We collect and process your Personal Information based on the
            following legal parameters depending upon the nature of Personal
            Information and the purposes for which it is processed:
          </p>
          <ul>
            <li>
              <strong>Consent:</strong> We rely on your consent to process your
              Personal Information in certain situations. If we require your
              consent to collect and process certain Personal Information, as
              per the requirements under the applicable Data Protection Laws,
              your consent is sought at the time of collection of your Personal
              Information, and such processing will be performed where consent
              is secured.
            </li>
            <li>
              <strong>Compliance with a Legal Obligation:</strong> Your Personal
              Information may be processed by us to the extent that such
              processing is necessary to comply with a legal obligation.
            </li>
          </ul>
          <p>
            <strong>Processing of Your Personal Information:</strong>
          </p>
          <p>
            We may process your Personal Information in connection with any of
            the purposes and uses set out in this Policy on one or more of the
            following legal grounds:
          </p>
          <ul>
            <li>
              Because it is necessary to perform the Services you have requested
              or to comply with your instructions or other contractual
              obligations between you and us;
            </li>
            <li>
              To comply with our legal obligations as well as to keep records of
              our compliance processes;
            </li>
            <li>
              Because our legitimate interests, or those of a third-party
              recipient of your Personal Information, make the processing
              necessary, provided those interests are not overridden by your
              interests or fundamental rights and freedoms;
            </li>
            <li>
              Because you have chosen to publish or display your Personal
              Information on a public area of the Platform, such as a comment
              area;
            </li>
            <li>Because it is necessary to protect your vital interests;</li>
            <li>Because it is necessary in the public interest; or</li>
            <li>
              Because you have expressly given us your consent to process your
              Personal Information in a particular manner.
            </li>
          </ul>
          <p>
            We do not use Personal Information for making any automated
            decisions affecting or creating profiles other than what is
            described in this Policy.
          </p>
          <p>
            Where the processing of your Personal Information is based on your
            consent, you have the right to withdraw your consent at any point in
            time in accordance with this Policy. Please note that should the
            withdrawal of consent result in us not being able to continue
            offering our Services to you, we reserve the right to withdraw or
            cease from offering our Services to you upon your consent
            withdrawal. You may withdraw consent by contacting us with a written
            request to the contact details provided in the ‘Grievances’ section
            below. Upon receipt of your request to withdraw your consent, the
            consequences of withdrawal may be communicated to you. Upon your
            agreement to the same, your request for withdrawal will be
            processed.
          </p>
          <h6 id="f.-how-we-use-and-share-the-information-collected">
            F. How We Use and Share the Information Collected
          </h6>
          <p>
            <strong>
              We use/process your information in the following manner:
            </strong>
          </p>
          <ol>
            <li>
              <p>
                <strong>To Provide Services on our Platform:</strong>
              </p>
              <ul>
                <li>
                  We use your information as collected by us to allow you to
                  access the Platform and the Services offered therein,
                  including without limitation to set-up and maintain your
                  account, provide customer service, fulfill purchases through
                  the Platform, verify User information, and to resolve any
                  glitches with our Platform. The legal basis for this
                  processing is consent or, where applicable, our legitimate
                  interests in the proper administration of our Platform, and/or
                  the performance of a contract between you and us.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>To Improve Our Platform and Maintain Safety:</strong>
              </p>
              <ul>
                <li>
                  We use your information to improve and customize the Platform
                  and Services offered by us, including providing automatic
                  updates to newer versions of our Platform and creating new
                  features based on the Platform usage analysis. Further, we
                  also use your information to prevent, detect, investigate, and
                  take measures against criminal activity, fraud, misuse of or
                  damage to our Platform or network, and other threats and
                  violations to ROOTs Edu’s or a third party’s rights and
                  property, or the safety of ROOTs Edu, its users, or others.
                  The legal basis for this processing is consent or, where
                  applicable, our legitimate interests in the proper
                  administration of our Platform, and/or the performance of a
                  contract between you and us.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>
                  To Market Our Platform and Communicate with You:
                </strong>
              </p>
              <ul>
                <li>
                  We will use your information to develop a more targeted
                  marketing of our Platform, to communicate with you about our
                  offers, new products, services, or to receive your feedback on
                  the Platform. The legal basis for this processing is consent
                  or, where applicable, our legitimate interests in the proper
                  administration of our Platform, and/or the performance of a
                  contract between you and us.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>To Establish, Exercise, or Defend Legal Claims:</strong>
              </p>
              <ul>
                <li>
                  We may process any Personal Information identified in this
                  Policy when necessary for establishing, exercising, or
                  defending legal claims, whether in court, administrative, or
                  other proceedings. The legal basis for this processing is our
                  legitimate interest in the protection and assertion of our
                  legal rights, your legal rights, and the legal rights of
                  others.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>To Manage Risk and Obtain Professional Advice:</strong>
              </p>
              <ul>
                <li>
                  We may process any of the Personal Information identified in
                  this Policy to manage risk or obtain professional advice. The
                  legal basis for this processing is our legitimate interest in
                  the proper protection of our business and Platform.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>With Your Consent:</strong>
              </p>
              <ul>
                <li>
                  We may otherwise use your information with your consent or at
                  your direction.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>To Better Understand Our Users:</strong>
              </p>
              <ul>
                <li>
                  We may use information we gather to determine which areas of
                  the Services are most frequently visited to understand how to
                  enhance the Services.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            <strong>
              We share the information collected as per the terms of this Policy
              only in the manner specified hereinbelow. We do not sell or
              otherwise disclose Personal Information we collect about you for
              monetary or other valuable consideration. Further, only authorized
              representatives of ROOTs Edu and on a need-to-know basis use any
              information received from you and as consented by you. In the
              event of any identified unauthorized use or disclosure of
              information or upon your complaint as stated under the
              ‘Grievances’ section below, we will investigate any such complaint
              and take the appropriate action as per the applicable Data
              Protection Laws.
            </strong>
          </p>
          <ul>
            <li>
              <p>
                <strong>
                  Third-Party Vendors/Service Providers Including Integrated
                  Services on the Platform:
                </strong>
              </p>
              <ul>
                <li>
                  We may share information about you with third-party vendors or
                  service providers (including consultants, payment processors,
                  and other service providers and integrated services) who need
                  the information to provide their support services to us or
                  you, or to provide their services to you on our behalf either
                  directly or through the Platform. These services may include
                  providing customer support, performing business and sales
                  analysis, supporting our website functionality, facilitating
                  payment processing, and supporting contests, surveys, and
                  other features offered on our Platform. Such third-party
                  vendors are not allowed to use the information for any purpose
                  other than what it was provided for, and they are required to
                  process and use the information in accordance with this
                  Privacy Policy.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Legal Disclosures:</strong>
              </p>
              <ul>
                <li>
                  We may disclose information about you in response to a court
                  order or other governmental request. Without limitation to the
                  foregoing, we reserve the right to disclose such information
                  where we believe in good faith that such disclosure is
                  necessary to:
                  <ul>
                    <li>
                      comply with applicable laws, regulations, court orders,
                      government and law enforcement agencies’ requests;
                    </li>
                    <li>
                      protect and defend ROOTs Edu’s or a third party’s rights
                      and property, or the safety of ROOTs Edu, our users, our
                      employees, or others; or
                    </li>
                    <li>
                      prevent, detect, investigate and take measures against
                      criminal activity, fraud, and misuse or unauthorized use
                      of our Platform and/or to enforce our Terms and Conditions
                      or other agreements or policies.
                    </li>
                  </ul>
                </li>
                <li>
                  To the extent permitted by law, we will attempt to give you
                  prior notice before disclosing your information in response to
                  such a request.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Business Transfers:</strong>
              </p>
              <ul>
                <li>
                  In the event ROOTs Edu undergoes any merger, acquisition, or
                  sale of company assets, in part or in full, with another
                  company, or in the unlikely event that ROOTs Edu goes out of
                  business or enters bankruptcy, user information would likely
                  be one of the assets that is transferred or acquired by a
                  third party. If any of these events were to happen, this
                  Privacy Policy would continue to apply to your information and
                  the party receiving your information may continue to use your
                  information, but only consistent with this Privacy Policy.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>Advertising and Analytics Partners:</strong>
              </p>
              <ul>
                <li>
                  We may share usage data with third-party advertisers,
                  advertisement networks, and analytics providers through
                  cookies and other similar technologies.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>With Your Consent:</strong>
              </p>
              <ul>
                <li>
                  We may share and disclose information with your consent or at
                  your direction.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Your information may be shared for reasons not described in this
            Policy; however, we will seek your consent before we do the same or
            share information upon your direction.
          </p>
          <h6 id="g.-duration-for-which-your-information-is-stored">
            G. Duration for Which Your Information is Stored
          </h6>
          <p>
            Mostly, when you delete any of the information provided by you or
            when you delete your account on the Platform, the same will be
            deleted from our servers too. However, in certain cases, we will
            retain your information for as long as it is required for us to
            retain for the purposes stated hereinabove, including for the
            purpose of complying with legal obligations or business compliances.
          </p>
          <p>
            Further, please note that we may not be able to delete all
            communications or photos, files, or other documents publicly made
            available by you on the Platform (for example, comments, feedback,
            etc.), however, we shall anonymize your Personal Information in such
            a way that you can no longer be identified as an individual in
            association with such information made available by you on the
            Platform. We will never disclose aggregated or de-identified
            information in a manner that could identify you as an individual.
          </p>
          <p>
            <strong>Note:</strong> If you wish to exercise any of your rights
            (as specified in the ‘Your Rights’ section below) to access, modify,
            and delete any or all information stored about you, then you may do
            so by using the options provided within the Platform. You can always
            write to us at{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>{" "}
            for any clarifications needed.
          </p>
          <h6 id="h.-your-choices">H. Your Choices</h6>
          <ul>
            <li>
              <p>
                <strong>Limit the Information You Provide:</strong> You always
                have the option to choose the information you provide to us,
                including the option to update or delete your information.
                However, please note that the lack of certain information may
                prevent you from accessing the Platform or any of its features,
                in part or in full.
              </p>
            </li>
            <li>
              <p>
                <strong>Limit the Communications You Receive from Us:</strong>{" "}
                You will also have the option to choose what kind of
                communication you would like to receive from us and whether you
                would like to receive such communication at all. However, there
                may be certain communications that are required for legal or
                security purposes, including changes to various legal
                agreements, that you may not be able to limit.
              </p>
            </li>
            <li>
              <p>
                <strong>Reject Cookies and Other Similar Technologies:</strong>{" "}
                You may reject or remove cookies from your web browser; you will
                always have the option to change the default settings on your
                web browser if it is set to ‘accept cookies’. However, please
                note that some Services offered on the Platform may not function
                or be available to you when cookies are rejected, removed, or
                disabled.
              </p>
            </li>
          </ul>
          <h6 id="i.-information-security">I. Information Security</h6>
          <p>
            We work to protect the security of your information during
            transmission by using Transport Layer Security (TLS) or Secure
            Sockets Layer (SSL) software (depending on your browser/Supported
            Device), which encrypts information you input in addition to
            maintaining the security of your information as required under
            applicable laws.
          </p>
          <p>
            We maintain electronic, and procedural safeguards in connection with
            the collection, storage, and disclosure of Personal Information
            (including Sensitive Personal Information). Our security procedures
            mean that we may occasionally request proof of identity before we
            disclose Personal Information to you that belongs to you.
          </p>
          <p>
            However, no form or method of data storage or transmission system is
            fully secure, and we cannot guarantee that security provided by such
            system(s) is absolute and that your information will not be
            accessed, disclosed, or destroyed in the event of a breach of any of
            our security measures.
          </p>
          <p>
            It is important for you to protect your account against unauthorized
            access to or use of your password and your computer. If you have any
            reason to believe that your password has become known to anyone
            else, or if the password is being, or is likely to be, used in an
            unauthorized manner, you must immediately change your password or
            inform us so that we can help you stop or prevent such unauthorized
            access. Be sure to sign off when you finish using a shared computer.
          </p>
          <h6 id="j.-promotional-communications">
            J. Promotional Communications
          </h6>
          <p>
            You will always have the option to opt-out of receiving some or all
            of our promotional communications by using the unsubscribe link in
            any email communications sent to you or by emailing{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>
            .
          </p>
          <p>
            If you opt out of promotional communications, we may still send you
            transactional communications, such as service announcements,
            administrative and legal notices, and information about your
            account, without offering you the opportunity to opt out of these
            communications. If you no longer wish to use our Platform or receive
            any communications from us (except for those that are legally
            required), then you may delete your account by using the option
            enabled within the Platform or by writing to us at{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>
            .
          </p>
          <p>
            Advertisers or ad companies working on their behalf sometimes use
            technology to serve the ads that appear on our sites directly to
            your browser. They automatically receive your IP address when this
            happens. They may also use cookies to measure the effectiveness of
            their ads and to personalize ad content. We do not have access to or
            control over cookies or other features that advertisers and
            third-party sites may use, and the information practices of these
            advertisers and third-party websites are not covered by our Policy.
            Please contact them directly for more information about their
            privacy practices.
          </p>
          <p>
            Please note that opting out of promotional email communications only
            affects future communications from us. If we have already provided
            your information to a third party (as stated in this Policy) before
            you changed your preferences or updated your information, you may
            have to change your preferences directly with that third party.
          </p>
          <p>
            We do not sell your Personal Information to third parties, and we do
            not use your name or the name of your company in marketing
            statements without your consent.
          </p>
          <h6 id="k.-interest-based-ads">K. Interest-Based Ads</h6>
          <p>
            On unaffiliated sites, ROOTs Edu may display interest-based
            advertising using information you make available to us when you
            interact with our Platform and Services. Interest-based ads, also
            sometimes referred to as personalized or targeted ads, are displayed
            to you based on information from activities such as registering with
            our Platform, visiting sites that contain ROOTs Edu content or ads.
            We do not provide any Personal Information to advertisers or to
            third-party sites that display our interest-based ads. However,
            advertisers and other third-parties (including the ad networks,
            ad-serving companies, and other service providers they may use) may
            assume that users who interact with or click on a personalized ad or
            content are part of the group that the ad or content is directed
            towards. Also, some third-parties may provide us information about
            you (such as the sites where you have been shown ads or demographic
            information) from offline and online sources that we may use to
            provide you more relevant and useful advertising.
          </p>
          <p>
            Advertisers or ad companies working on their behalf sometimes use
            technology to serve the ads that appear on our sites directly to
            your browser. They automatically receive your IP address when this
            happens. They may also use cookies to measure the effectiveness of
            their ads and to personalize ad content. We do not have access to or
            control over cookies or other features that advertisers and
            third-party sites may use, and the information practices of these
            advertisers and third-party websites are not covered by our Policy.
            Please contact them directly for more information about their
            privacy practices.
          </p>
          <h6 id="l.-modification-to-privacy-policy">
            L. Modification to Privacy Policy
          </h6>
          <p>
            Our business changes constantly, and our Policy may change from time
            to time. We may, at our discretion (unless required by applicable
            laws to mandatorily do so), email periodic reminders of our notices
            and conditions, unless you have instructed us not to. However, we
            encourage you to check our Platform frequently to see the recent
            changes. Unless stated otherwise, our current Policy applies to all
            information that we have about you and your account. We stand behind
            the promises we make and will not materially change our policies and
            practices, making them less protective of customer information
            collected in the past without your consent.
          </p>
          <h6 id="m.-privacy-grievances">M. Privacy Grievances</h6>
          <p>
            If you have any questions about this Policy, wish to exercise your
            rights, have concerns about the privacy of your data, or any
            privacy-related grievances in respect of the Platform, then please
            register your complaint with a thorough description via email to{" "}
            <a href="mailto:contact@rootsedulive.com">
              contact@rootsedulive.com
            </a>{" "}
            or call our hotline at 01789114390.
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
