
  export const actionTypes = {
    Success: "success",
    Danger: "error",
    Warning: "warning",
    Info: "info",
    Close : "close"
  };



  export const Close = () => async (dispatch) => {
    try {
       
       dispatch({ type: actionTypes.Close, payload: "" });
    } catch (error) {
      
      dispatch({ type: actionTypes.Danger, payload: "Something went wrong." });
    }
  };
  
  export const Success = (message) => async (dispatch) => {
    try {
       
       dispatch({ type: actionTypes.Success, payload: message });

      const timeId = setTimeout(() => {
        dispatch({ type: actionTypes.Close, payload: "" });
      }, 5000)
      return () => {
        clearTimeout(timeId)
      }

    } catch (error) {
     
      dispatch({ type: actionTypes.Danger, payload: "Something went wrong." });
    }
  };

  export const Danger = (message) => async (dispatch) => {
    try {
       dispatch({ type: actionTypes.Danger, payload: message });

       const timeId = setTimeout(() => {
        dispatch({ type: actionTypes.Close, payload: "" });
      }, 5000)
      return () => {
        clearTimeout(timeId)
      }

    } catch (error) {

      dispatch({ type: actionTypes.Danger, payload: "Something went wrong." });
    }
  };


  export const Warning = (message) => async (dispatch) => {
    try {
       dispatch({ type: actionTypes.Warning, payload: message });

       const timeId = setTimeout(() => {
        dispatch({ type: actionTypes.Close, payload: "" });
      }, 5000)
      return () => {
        clearTimeout(timeId)
      }

    } catch (error) {
     
      dispatch({ type: actionTypes.Danger, payload: "Something went wrong." });
    }
  };

  export const Info = (message) => async (dispatch) => {
    try {
       dispatch({ type: actionTypes.Info, payload: message });

       const timeId = setTimeout(() => {
        dispatch({ type: actionTypes.Close, payload: "" });
      }, 5000)
      return () => {
        clearTimeout(timeId)
      }

    } catch (error) {
      
      dispatch({ type: actionTypes.Danger, payload: "Something went wrong." });
    }
  };