import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { FcAlarmClock } from "react-icons/fc";
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { BiHash } from "react-icons/bi"
import { TiTickOutline } from 'react-icons/ti';
import { ImCross } from "react-icons/im";

export default function Header({
  onTimeComplete,
  title,
  course,
  examType,
  noOfQuestion,
  marksPerQuestion,
  negativeMarksPerQuestion,
  durationInSec,
}) {
  const startDate = React.useRef(Date.now());
  const Completionist = () => <span>Your Time is Over Now !</span>;
  const defaultRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      const countTimer = { hours, minutes, seconds };
      localStorage.setItem("Quiz_Completion_Time", JSON.stringify(countTimer));
      onTimeComplete();
      return <Completionist />;
    } else {
      const countTimer = { hours, minutes, seconds };
      localStorage.setItem("Quiz_Completion_Time", JSON.stringify(countTimer));
      return (
        <span className="timer-count-down">
          {hours}h : {minutes}m : {seconds}s
        </span>
      );
    }
  };
  return (
    <>
      <div className="quiz-header">
        <h2>{title}</h2>
        <div className="exam-info">
          <div>
            <h5><BsFillQuestionCircleFill />{" "}Total Questions: {noOfQuestion} </h5>
            <h5><BiHash />{" "}Total Marks: {noOfQuestion * marksPerQuestion}</h5>
            <h5 style={{ color: 'green' }}><span style={{ fontSize: '30px' }}><TiTickOutline />{""}</span>{marksPerQuestion} Mark/Question</h5>
            <h5 style={{ color: 'red' }}><span style={{ fontSize: '20px' }}><ImCross />{" "}</span>{negativeMarksPerQuestion}/Wrong
              Answer</h5>
            <h5>
              <FcAlarmClock />{" "}
              {(durationInSec / 60).toFixed(2)} Minutes
            </h5>
          </div>
          <div className="countdown">
            <h5> Remaining Time: </h5>
            <Countdown
              renderer={defaultRenderer}
              date={startDate.current + durationInSec * 1000}
            />
          </div>
        </div>
      </div>
    </>
  );
}
