import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
/* @mui component */
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NoticeLoading from "./NoticeLoading";
/* @custom component */
import axios from "../../../axios";

export default function WrittenExam() {
  const params = new URLSearchParams(window.location.search);
  /* @query params */
  const {id} = useParams()
  
  /* @local state */
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchNotice(id);
  }, [id]);

  const fetchNotice = async (id) => {
    try {
      let url = `/android-app/notification/notice-details/${id}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const noticeUi = (
    <Box spacing={2} sx={{ p: 1 }}>
      <Typography sx={{fontWeight: 600}} textAlign={"center"} variant="h6" gutterBottom>
        {data?.title}
      </Typography>
      <Typography textAlign={"center"} variant="body1" gutterBottom>
        <div dangerouslySetInnerHTML={{ __html: `${data?.description}` }} />
      </Typography>
    </Box>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {!loading && !data ? <h4>Empty Notice Board.</h4> : null}
            {loading && <NoticeLoading />}
            {!loading && data && noticeUi}
          </Stack>
      </Container>
    </React.Fragment>
  );
}
