
import "bootstrap/dist/css/bootstrap.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
import { useEffect } from "react";
import Main from './Main'
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createTheme , ThemeProvider} from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import SnackBar from "./components/Toaster/SnackBar";
import {getAllGoal} from "./redux/action/goals";
import AuthModal from "./Pages/WebAuth/AuthModal";
function App(){

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllGoal());
  }, [])

  const theme = createTheme({
    typography:{
      h1:{
        
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root:{
            background:"#fff"
           
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select:{
            background:"#fff"
           
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root:{
            background:"#fff"
          }
        }
      }
    },
    palette: {
      primary: {
        main: "#0066CC",
      },
    },
  });
  return (
    <>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackBar />
            <AuthModal />
            <Main />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>

    </>
  );
}

export default App;
