import React, { useState } from "react";

import Pagination from "@mui/material/Pagination";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PaginationItem from "@mui/material/PaginationItem";
import "./style.css";


export default function Footer({
  noOfQuestion,
  pagerender,
  selectAnswer,
  handleSubmitQuiz,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (event, page) => {
    pagerender(page);
    setCurrentPage(page);
  };


  //handing submit
  const handleSubmit = () => {
    handleSubmitQuiz();
  };

  const handlePageIncrease = () => {
    pagerender(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const handlePageDecrease = () => {
    pagerender(currentPage - 1);
    setCurrentPage(currentPage - 1);
  };
  var pageBoundary = 0;
  if (noOfQuestion % 2 == 0) {
    pageBoundary = noOfQuestion / 2;
  } else {
    pageBoundary = Math.ceil(noOfQuestion / 2); //+ 1;
  }
  const MarkPage = [];
  if (selectAnswer.length > 0) {
    selectAnswer.map((item) => {
      MarkPage.push(item.question + 1);
    });
  }
  return (
    <>
    <div className="footer-btn-group">
        {currentPage == 1 ? null : (
          <a
            className="btn"
            onClick={handlePageDecrease}
            startIcon={<NavigateBeforeIcon />}
            style={{backgroundColor : '#0066CC'}}
          >
            Previous
          </a>
        )}
        {currentPage == noOfQuestion ? null : (
          <a
            className="btn"
            onClick={handlePageIncrease}
            startIcon={<NavigateNextIcon />}
            style={{backgroundColor : '#0066CC'}}
          >
            Next
          </a>
        )}
      </div>

      <div className="btn-group centerXY">
        <Pagination
          page={currentPage}
          onChange={handleChangePage}
          boundaryCount={pageBoundary}
          count={noOfQuestion}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              className={MarkPage.includes(item.page) ? "bg-green" : null}
            />
          )}
        />
      </div>
      
      {/* <div style={{backgroundColor : '#0066CC'}} className="btn-block btn" onClick={handleSubmit}>
        Submit
      </div> */}
      
    </>
  );
}
