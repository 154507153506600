const GET_ALL_GOAL = 'GET_ALL_GOAL'
const LOADING = 'LOADING';
const INITIAL_STATE = {
    loading : true,
    Goals :[]
};
const goals = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_GOAL:
            return {
                ...state, 
                loading: false , 
                Goals:action.payload.data
            };
        case LOADING:
            return {
                ...state, loading: true
            };
        default: return { ...state, loading: true };
    }
};
export default goals;