import React, { useState , useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import Avatar from '@mui/material/Avatar';
import Paper from "@mui/material/Paper";
import {themeContext} from "../../context/ThemeContext";
import {RenderMathElement} from "../App/mathjaxPreview/MemoizedMathJaxComponent";

export default function Question({ question, noOfQuestion, page, onchangeSelectAnswer }) {

  const {mode} = React.useContext(themeContext)
  const [storeAnswer, setStoreAnswer] = useState([]);

  const handleClickanswer = (q_num, selected) => {
    if (storeAnswer.length > 0) {
      const removeItem = storeAnswer.filter(item => item.question != q_num);
      setStoreAnswer([...removeItem, { question: q_num, selected: selected }])
      onchangeSelectAnswer([...removeItem, { question: q_num, selected: selected }]); // SEND DATA TO PARENT CLASS
    }
    else {
      setStoreAnswer([...storeAnswer, { question: q_num, selected: selected }])
      onchangeSelectAnswer([...storeAnswer, { question: q_num, selected: selected }]); // SEND DATA TO PARENT CLASS
    }
  }

  var selec_Option_A = false;
  var selec_Option_B = false;
  var selec_Option_C = false;
  var selec_Option_D = false;
  var selec_Option_E = false;

  if (storeAnswer.length > 0) {
    storeAnswer.map(item => {
      if (item.question == page && item.selected == 'A') {
        selec_Option_A = true;
      }
      else if (item.question == page && item.selected == 'B') {
        selec_Option_B = true;
      }
      else if (item.question == page && item.selected == 'C') {
        selec_Option_C = true;
      }
      else if (item.question == page && item.selected == 'D') {
        selec_Option_D = true;
      }
      else if (item.question == page && item.selected == 'E') {
        selec_Option_E = true;
      }
    })
  }

  return (
    <Row style={{padding : '0px 30px'}}>
     <Paper rounded={true}  elevation={4} square component={Col} style={{margin : '15px 0px', borderRadius: '5px', padding : '10px 30px'}} md={12}>
      <Row className="total-question" style={mode === 'dark' ? { marginTop: '15px', color : 'white' } : { marginTop: '15px'}}>
        {/* QUESTION {page + 1} OF {noOfQuestion} */}
        
        <Row className="question-shown" style={{ overflowX : 'auto', marginTop: '15px', marginBottom: '15px', padding : '5px' }}>
          <Col md={12}>
          <RenderMathElement data={`${page+1}. &nbsp; ${question[page].question}`} />
          </Col>
       </Row>
        
      </Row>

      <Row onClick={() => handleClickanswer(page, 'A')} className={selec_Option_A ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{  padding: '10px', width: '100%', flexFlow: 'row' }}>
        <Avatar className={selec_Option_A ? "ques-ans-selected" : "ques-ans-Unselected"}>A</Avatar> &nbsp; &nbsp; 
        {/* <RenderMathElement data={question[page].optionA} /> */}
        <div style={{overflowX : 'auto', padding : '5px'}}><RenderMathElement data={question[page].optionA} /></div>
      </Row>

      <Row onClick={() => handleClickanswer(page, 'B')} className={selec_Option_B ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
        <Avatar className={selec_Option_B ? "ques-ans-selected" : "ques-ans-Unselected"}>B</Avatar> &nbsp; &nbsp; 
        <div style={{overflowX : 'auto', padding : '5px'}}><RenderMathElement  data={question[page].optionB} /></div>
      </Row>

      <Row onClick={() => handleClickanswer(page, 'C')} className={selec_Option_C ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
        <Avatar className={selec_Option_C ? "ques-ans-selected" : "ques-ans-Unselected"}>C</Avatar> &nbsp; &nbsp; 
        {/* <RenderMathElement  data={question[page].optionC} /> */}
        <div style={{overflowX : 'auto', padding : '5px'}}><RenderMathElement  data={question[page].optionC} /></div>

      </Row>

      <Row onClick={() => handleClickanswer(page, 'D')} className={selec_Option_D ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
        <Avatar className={selec_Option_D ? "ques-ans-selected" : "ques-ans-Unselected"}>D</Avatar> &nbsp; &nbsp; 
        {/* <RenderMathElement  data={question[page].optionD} /> */}
        <div style={{overflowX : 'auto', padding : '5px'}}><RenderMathElement  data={question[page].optionD} /></div>
      </Row>
      {
        question[page].optionE ?
        <Row onClick={() => handleClickanswer(page, 'E')} className={selec_Option_E ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row' }}>
        <Avatar className={selec_Option_E ? "ques-ans-selected" : "ques-ans-Unselected"}>E</Avatar> &nbsp; &nbsp; 
         <div style={{overflowX : 'auto', padding : '5px'}}><RenderMathElement  data={question[page].optionE} /></div>
      </Row> : null
      }

    </Paper>
    </Row>
  );
}