import { LOGIN, LOGOUT, REGISTRACTION, HIDE_AUTH_MODAL,SEND_OTP, VERIFY_INSTITUTION, SWITCH_AUTH_PAGES, TOGGLE_AUTH_MODAL, CHANGE_PASSWORD, VERIFY_OTP, LOADING } from "../constants/index";

const INITIAL_STATE = {
    showModal: false,
    showPage: LOGIN,
    loading: false,
    phoneOrEmail: '',
    forgetPassword: false,
    token: null,
    isVerifiedInstitution: false,
    institution: ''
};
const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state, loading: true
            };
        case SWITCH_AUTH_PAGES:
            return {
                ...state, loading: true,
                showPage: action.payload.pageName,
                forgetPassword: action.payload.forgetPassword
            };
        case CHANGE_PASSWORD:
            return {
                ...state, showPage: CHANGE_PASSWORD
            };
        case LOGOUT:
            localStorage.removeItem("Token")
            return {
                ...state,
                loading: false,
                token: null
            };
        case LOGIN:
                return {
                    ...state,
                    loading: false,
                    showModal: action.payload.showModal,
                    token: localStorage.getItem("Token")
                };
        case REGISTRACTION:
            return {
                ...state, loading: false, showPage: LOGIN
            };
        case TOGGLE_AUTH_MODAL:
            return {
                ...state, showModal: action.payload,
                showPage: LOGIN,
                phoneOrEmail: null,
                forgetPassword: null
            };
        case SEND_OTP:
            return {
                ...state, phoneOrEmail: action.payload, showPage: VERIFY_OTP
            };
        case VERIFY_OTP:
            return {
                ...state,
                phoneOrEmail: action.payload,
                showPage: VERIFY_OTP,
                token: localStorage.getItem("Token")
            }
        case VERIFY_INSTITUTION:
           
            const Verified_Institution = JSON.parse(localStorage.getItem("isVerifiedInstitution")) ? JSON.parse(localStorage.getItem("isVerifiedInstitution")) : {isValidInstitute : true , institution : ''};
            return {
                ...state,
                isVerifiedInstitution: Verified_Institution.isValidInstitute,
                institution: Verified_Institution.institution,
                token: localStorage.getItem("Token")
            }
        case HIDE_AUTH_MODAL:
                return {
                    ...state,
                    showModal: false,
                    token: localStorage.getItem("Token")
                }
        default:  
        const VerifiedInstitution = JSON.parse(localStorage.getItem("isVerifiedInstitution")) ?JSON.parse(localStorage.getItem("isVerifiedInstitution")) : {isValidInstitute : true , institution : ''};
        return {
            ...state, token: localStorage.getItem("Token"), isVerifiedInstitution: VerifiedInstitution.isValidInstitute,
            institution: VerifiedInstitution.institution,
        };
    }
};
export default auth;