import axios from "../../axios";
const GET_ALL_GOAL = 'GET_ALL_GOAL'
const LOADING = 'LOADING';

export const getAllGoal = () => async (dispatch) => {
  try {
    dispatch({type: LOADING})
    const response = await axios.get(`/goals`);
    dispatch({ type: GET_ALL_GOAL, payload: {data:response.data}});
  } catch (error) {
   // dispatch(Danger(`${error.response.data}`))
  }
};

