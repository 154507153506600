import React from "react";
import { Col, Row } from "react-bootstrap";
import { BiHash } from "react-icons/bi"
import { TiTickOutline } from 'react-icons/ti';
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { ImCross } from "react-icons/im";
import Countdown from 'react-countdown';
function Header(
    { onTimeComplete,
        title,
        examType,
        noOfQuestion,
        marksPerQuestion,
        durationInSec,
        negativeMarksPerQuestion
    }) {
    const startDate = React.useRef(Date.now());
    const Completionist = () => <span>Your Time is Over Now !</span>;
    const defaultRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            const countTimer = { hours, minutes, seconds }
            localStorage.setItem("Quiz_Completion_Time", JSON.stringify(countTimer));
            onTimeComplete()
            return <Completionist />;
        } else {
            const countTimer = { hours, minutes, seconds }
            localStorage.setItem("Quiz_Completion_Time", JSON.stringify(countTimer));
            return <span className="timer-count-down">{hours}h : {minutes}m : {seconds}s</span>;
        }
    };
    return (
        <>
            <Col md={6} sm={12}>
                <Row className="que-header-title centerXY mtb" style={{ padding: '15px', marginTop: '15px', fontSize: '14px', color : 'rgb(41, 83, 155)' }}>
                    {title}
                </Row>

            
                <Row className="que-header-text centerXY" style={{ marginTop: '15px', fontSize: '14px' }}>
                    <Col className="centerXY" md={6} sm={12}>
                     <BsFillQuestionCircleFill /> &nbsp;Total Question: {noOfQuestion}
                    </Col>
                </Row>

                <Row className="que-header-text centerXY" style={{ marginTop: '15px', fontSize: '14px' }}>
                    <Col className="centerXY" md={6} sm={12}>
                    <BiHash />&nbsp;Total Marks: {noOfQuestion * marksPerQuestion}
                    </Col>
                </Row>

                <Row className="que-header-text centerXY" style={{ marginTop: '15px', fontSize: '14px', textAlign : 'center' }}>
                    <Col className="centerXY" md={6} sm={12}>
                        <div style={{direction : 'column' , color : 'green'}} className="str centerXY"> 
                            <TiTickOutline />&nbsp;{marksPerQuestion} Mark/Question
                        </div>
                    </Col>

                    <Col style={{margin : '10px 0px'}} className="centerXY" md={6} sm={12}>
                        <div style={{direction : 'column', color : 'red'}} className="str centerXY"> 
                            <ImCross style={{fontSize : '10px'}} />&nbsp;-{negativeMarksPerQuestion}/Wrong Question
                        </div>
                    </Col>

                    <Col style={{margin : '10px 0px'}} className="centerXY" md={6} sm={12}>
                        ⏰&nbsp;{(durationInSec/60).toFixed(2)} Minutes
                    </Col>
                </Row>
               
                
            </Col>
            <Col style={{ fontSize: '14px' }} className="d-flex flex-column centerXY" md={6} sm={12}>
                <Row className="timer-header">
                    Remaining Time
                </Row>
                <Row>
                    <Countdown
                        renderer={defaultRenderer}
                        date={startDate.current + durationInSec * 1000}
                    />
                </Row>
            </Col>
        </>
    );
}
export const RenderHeader = React.memo(Header);