import React, { useState, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Avatar from '@mui/material/Avatar';
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button"
import Pagination from '@mui/material/Pagination';
import PaginationItem from "@mui/material/PaginationItem";
import { RenderMathElement } from "./MemoizedMathJaxComponent";
import "../../Quiz/style.css";

function Question({ question, noOfQuestion, page, onchangeSelectAnswer }) {

  const [storeAnswer, setStoreAnswer] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  const handleClickanswer = (q_num, selected) => {
    if (storeAnswer.length > 0) {
      const removeItem = storeAnswer.filter(item => item.question != q_num);
      setStoreAnswer([...removeItem, { question: q_num, selected: selected }])
      onchangeSelectAnswer([...removeItem, { question: q_num, selected: selected }]); // SEND DATA TO PARENT CLASS
    }
    else {
      setStoreAnswer([...storeAnswer, { question: q_num, selected: selected }])
      onchangeSelectAnswer([...storeAnswer, { question: q_num, selected: selected }]); // SEND DATA TO PARENT CLASS
    }
  }

  const MarkPage = [];
  if (storeAnswer.length > 0) {
    storeAnswer.map((item) => {
      MarkPage.push(item.question + 1);
    });
  }

  const MemoizedChildComponent = useMemo(
    () => <>{
      <Paper style={{ width: '100%', borderRadius : '5px' }} square>
        <Row className="question-shown" style={{ marginBottom: '10px', marginTop : '10px', fontSize: '14px', overflowX: 'auto' }}>
          <RenderMathElement data={`${currentPage}. &nbsp; ${question[currentPage - 1].question}`} />
        </Row>

        <Row onClick={() => handleClickanswer(currentPage - 1, 'A')}
          className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'A')) ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"}
          style={{ padding: '10px', width: '100%', flexFlow: 'row', fontSize: '14px' }}>
          <Avatar style={{ width: '25px !important', height: '25px !important', fontSize: '14px' }} className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'A')) ? "ques-ans-selected" : "ques-ans-Unselected"}>A</Avatar> &nbsp; &nbsp;
          <RenderMathElement data={question[currentPage - 1].optionA} />
        </Row>

        <Row onClick={() => handleClickanswer(currentPage - 1, 'B')}
          className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'B')) ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row', fontSize: '14px' }}>
          <Avatar style={{ width: '25px !important', height: '25px !important', fontSize: '12px' }} className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'B')) ? "ques-ans-selected" : "ques-ans-Unselected"}>B</Avatar> &nbsp; &nbsp;
          <RenderMathElement data={question[currentPage - 1].optionB} />
        </Row>

        <Row onClick={() => handleClickanswer(currentPage - 1, 'C')}
          className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'C')) ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row', fontSize: '14px' }}>
          <Avatar style={{ width: '25px !important', height: '25px !important', fontSize: '12px' }} className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'C')) ? "ques-ans-selected" : "ques-ans-Unselected"}>C</Avatar> &nbsp; &nbsp;
          <RenderMathElement data={question[currentPage - 1].optionC} />
        </Row>

        <Row onClick={() => handleClickanswer(currentPage - 1, 'D')}
          className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'D')) ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row', fontSize: '14px' }}>
          <Avatar style={{ width: '25px !important', height: '25px !important', fontSize: '12px' }} className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'D')) ? "ques-ans-selected" : "ques-ans-Unselected"}>D</Avatar> &nbsp; &nbsp;
          <RenderMathElement data={question[currentPage - 1].optionD} />
        </Row>
        {
            question[currentPage - 1].optionE ?
            <Row onClick={() => handleClickanswer(currentPage - 1, 'E')}
              className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'E')) ? "ques-main-div-selected centerXY" : "hover-item que-top-shadow centerXY"} style={{ padding: '10px', width: '100%', flexFlow: 'row', fontSize: '14px' }}>
              <Avatar style={{ width: '25px !important', height: '25px !important', fontSize: '12px' }} className={Boolean(storeAnswer.find(item2 => item2.question == currentPage - 1 && item2.selected == 'E')) ? "ques-ans-selected" : "ques-ans-Unselected"}>E</Avatar> &nbsp; &nbsp;
              <RenderMathElement data={question[currentPage - 1].optionE} />
            </Row> : null
        }
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{width : '100%', position : 'relative', mb: 3, mt : 2}}
        >
         {currentPage === 1 ? null : <Button onClick={()=> {if(currentPage > 1) {setCurrentPage(currentPage - 1)} }} sx={{textTransform : 'none', w : '80px', position : 'absolute', left : '5px', mt : 1}} variant="contained">Previous</Button>}
         {currentPage === question.length ? null :  <Button  onClick={()=> {if(currentPage < question.length) {setCurrentPage(currentPage + 1)} }} sx={{textTransform : 'none', w : '80px', position : 'absolute', right : '5px', mt : 1}} variant="contained">Next</Button>}
        </Grid>

        <Pagination
          page={currentPage}
          sx={{ p: 1 }}
          count={question.length}
          onChange={handleChangePage}
          boundaryCount={question.length}
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              className={MarkPage.includes(item.page) ? "bg-green" : null}
            />
          )}
        />
      </Paper>
    } </>,
    [storeAnswer, currentPage]
  );

  return (
    <>
      {MemoizedChildComponent}
    </>
  )
}
export const RenderMathQuestion = React.memo(Question);

